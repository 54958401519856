// import CurrencyFormat from "react-currency-format";
import useBookingContext from "./useBookingContext";
import { useEffect } from "react";

const columns = [
    {
      name: "Room Number",
      selector: (row) => row.room_number,
      sortable: true,
    },
    {
      name: "Room Type",
      selector: (row) => row.type,
      sortable: true,
    },
    // {
    //   name: "Rent",
    //   selector: (row) => <CurrencyFormat prefix="৳" value={+row.rent} suffix="/-" displayType="text" thousandSeparator="," />,
    //   sortable: true,
    // },
    // {
    //   name: "Actions",
    //   selector: (row) => row.action,
    //   sortable: true,
    //   cell: () => <DropdownLink firstLink={"/room-details"} />,
    //   ignoreRowClick: true,
    //   allowOverflow: true,
    //   button: true,
    // },
  ];

const useAvailableRooms = (check_in, check_out) => {

	const { loadAvailableRooms, availableRooms, isAvailableRoomsLoading } = useBookingContext();

  useEffect(() => {
    const checkInDate = new Date(check_in);
    const checkOutDate = new Date(check_out);
    if (check_in && check_out && checkInDate < checkOutDate) {
      loadAvailableRooms(check_in, check_out);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [check_in, check_out]);

  return {
		columns,
    isAvailableRoomsLoading,
		data: availableRooms.map(({id, room_number, room_type, price }) => {
			return {
				id,
				room_number,
				type: room_type?.name || 'N/A',
				rent: price,
			}
		}),
  };
}

export default useAvailableRooms;