/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import useGuestContext from "./useGuestContext";
import useBookingContext from "./useBookingContext";
import { useNavigate } from "react-router-dom";
import { ROUTE_PREFIX } from "../common/constants";
import moment from "moment-timezone";
import ToastPopup from "../common/ToastPopup";

const defaultBookingInfo = {
  adult_guest: 0,
  child_guest: 0,
  total_price: 0,
  paid_amount: 0,
  check_in: '',
  check_out: '',
};

const useBookingForm = (isEdit = false) => {
  const { isLoading: isGuestLoading, guests } = useGuestContext();
  const {
    availableRooms,
    loadAvailableRooms,
    isAvailableRoomsLoading,
    storeNewBooking,
    setIsLoading,
    loadBookingById,
    updateRoomBooking,
    isLoading,
  } = useBookingContext();
  const navigate = useNavigate();

  const [isNewCustomer, setIsNewCustomer] = useState(true);
  const [selectedGuest, setSelectedGuest] = useState({});
  const [guestInfo, setGuestInfo] = useState({});
  const [bookingInfo, setBookingInfo] = useState(defaultBookingInfo);
  const [selectedRooms, setSelectedRooms] = useState([]);
  const [bookingId, setBookingId] = useState(0);
  const [referenceInfo, setReferenceInfo] = useState({});

  const handleIsNewCustomer = ({value}) => {
    setIsNewCustomer(value);
    if (!value) {
      setSelectedGuest({})
    } else {
      setGuestInfo({});
    }
  };
  
  const handleSelectedGuestChange = (value) => setSelectedGuest(value);

  const handleGuestInfoChange = (field, value) => {
    setGuestInfo({
      ...guestInfo,
      [field]: value
    });
  }

  const handleReferenceInfoChange = (field, value) => {
    setReferenceInfo({
      ...referenceInfo,
      [field]: value
    });
  }

  const handleBookingInfoChange = (field, value) => {
    if (!['check_in', 'check_out'].includes(field)) {
      if (parseInt(value) < 0) {
        value = "0";
      }
    } else {
      value = moment(value).format('YYYY-MM-DD');
      if (bookingInfo[field] === value) {
        return;
      }
    }
    setBookingInfo({
      ...bookingInfo,
      [field]: value
    });
  }

  const handleSelectedRoomChange = (values) => {
    setSelectedRooms(values);
  };

  useEffect(() => {
    loadAvailableRooms(bookingInfo.check_in, bookingInfo.check_out, bookingId ? bookingId : null);
  }, []);

  useEffect(() => {
    if (bookingInfo.check_in && bookingInfo.check_out) {
      if (moment(bookingInfo.check_in).isAfter(moment(bookingInfo.check_out))) {
        ToastPopup('warning', 'Check-out date cannot be before Check-in date!');
        return;
      }
      if (!isAvailableRoomsLoading) {
        (async () => await loadAvailableRooms(bookingInfo.check_in, bookingInfo.check_out, bookingId ? bookingId : null))(); 
      }
    }
  }, [bookingInfo.check_in, bookingInfo.check_out])

  useEffect(() => {
    // TODO: lock the newly selected rooms later
  }, [selectedRooms]);
  useEffect(() => {

    setSelectedRooms([]);
  }, [availableRooms])

  const handleEditState = async (bookingId) => {
    setIsLoading(true);
    const response = await loadBookingById(bookingId, {includeRooms: true, includeGuest: true});
    if (!response?.id) {
      setIsLoading(false);
      return false;
    }

    const {
      id,
      total_price,
      paid_amount,
      check_in,
      check_out,
      adult_guest,
      child_guest,
      rooms,
      guest,
      reference_info,
    } = response;

    setBookingId(id);
    setBookingInfo({
      ...bookingInfo,
      total_price,
      paid_amount,
      check_in: check_in.split(' ')[0],
      check_out: check_out.split(' ')[0],
      adult_guest,
      child_guest,
    });
    setReferenceInfo(reference_info);

    setTimeout(() => {
      handleSelectedRoomChange(rooms.map(({ id: value, room_number: label }) => ({ value, label })));
    }, 2000);
    handleIsNewCustomer({ value: false });
    handleSelectedGuestChange({ value: guest.id, label: `${guest.full_name}-${guest.phone}` });
    setIsLoading(false);
    return true;
  }

  // const validateFormData = () => {
//    let validated = true;
    // if (!roomName) {
    //   validated = false;
    // }
  // };

  const handleFormSubmit = async () => {
    // if (!validateFormData()) {
    //   return;
    // }

    const requestObject = {
      new_guest: isNewCustomer,
      guest_id: isNewCustomer ? null : selectedGuest.value,
      guest: isNewCustomer ? guestInfo : null,
      check_in: bookingInfo.check_in,
      check_out: bookingInfo.check_out,
      total_price: +(bookingInfo.total_price),
      paid_amount: +(bookingInfo.paid_amount),
      adult_guest: +(bookingInfo.adult_guest),
      child_guest: +(bookingInfo.child_guest),
      details: selectedRooms.map(r => ({ room_id: +(r.value) })),
      referenceInfo,
    };

    if (isEdit) {
      const success = await updateRoomBooking({ ...requestObject, id: bookingId });
      if (success) {
        navigate(ROUTE_PREFIX + '/room-bookings');
      }
    } else {
      const success = await storeNewBooking(requestObject);
      if (success) {
        navigate(ROUTE_PREFIX + '/room-bookings');
      }
    }
  };

  return {
    isNewCustomer,
    handleIsNewCustomer,
    isGuestLoading,
    guests,
    selectedGuest,
    handleSelectedGuestChange,
    guestInfo,
    handleGuestInfoChange,
    referenceInfo,
    handleReferenceInfoChange,
    bookingInfo,
    handleBookingInfoChange,
    availableRooms,
    isAvailableRoomsLoading,
    handleSelectedRoomChange,
    selectedRooms,
    handleFormSubmit,
    handleEditState,
    isLoading,
  };
}

export default useBookingForm;