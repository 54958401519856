/* eslint-disable react-hooks/exhaustive-deps */
import DataTable from "react-data-table-component";
import useUsersList from '../../hooks/useUsersList';
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { ROUTE_PREFIX } from "../../common/constants";
import useIsAdmin from "../../hooks/useIsAdmin";

export default function Users() {
  const { columns, data, isLoading, loadUsers } = useUsersList();
  const [showModal, setShowModal] = useState(false);
  const isAdmin = useIsAdmin();
  useEffect(() => {
    loadUsers();
  }, []);
  return (
    <div className="shadow rounded bg-white">
      <div className="row justify-content-between align-items-center p-3">
        <div className="col-auto">
          <h5 className="mb-0 fw-bold">List of Users</h5>
        </div>
        {isAdmin && (<div className="col-auto">
          <Link to={`${ROUTE_PREFIX}/add-user`} type="button" className="btn btn-md btn-primary mb-0 rounded-1" onClick={() => setShowModal(!showModal)}>
            Create User
          </Link>
        </div>)}
      </div>
      <div className="row">
        <div className="col-12">
          <DataTable columns={columns} progressPending={isLoading} data={data} fixedHeader fixedHeaderScrollHeight="60vh" highlightOnHover />
        </div>
      </div>
    </div>
  );
}
