import Modal from '../../Interface/Modal';
import BookingPaymentForm from './BookingPaymentForm';
import BookingPaymentHistory from './BookingPaymentHistory';
import usePaymentForm from '../../../hooks/usePaymentForm';

const BookingPaymentModal = ({ showModal, setShowModal, bookingId, invoiceGenerator }) => {
  const {
    amount = 0,
    isLoading,
    handleAmountChange,
    handleFormSubmit,
    getPaymentById,
    setPaymentId,
    paymentId
  } = usePaymentForm(bookingId);

  return (
    <Modal
      show={showModal}
      setShowModal={setShowModal}
      title={`Payment history for booking #${bookingId}`}
      primaryAction={invoiceGenerator}
      primaryActionText={'Invoice'}
      style={{ minWidth: '70%' }}>
      <div className="shadow rounded bg-white">
        <div className="row">
          <BookingPaymentForm
            bookingId={bookingId}
            formData={{
              amount,
              isLoading,
              handleAmountChange,
              handleFormSubmit,
              getPaymentById,
              setPaymentId,
              paymentId
            }}  
          />
        </div>
        <BookingPaymentHistory
          setPaymentId={setPaymentId}
          bookingId={bookingId}
        />
      </div>
    </Modal>);
}

export default BookingPaymentModal;