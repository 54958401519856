import Select from "react-select";
import useBookingForm from "../../hooks/useBookingForm";
import { useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { ROUTE_PREFIX } from "../../common/constants";
import DatePicker from "react-datepicker";

export default function AddBooking({ isEdit }) {
  const {
    isNewCustomer,
    handleIsNewCustomer,
    guests,
    selectedGuest,
    handleSelectedGuestChange,
    handleGuestInfoChange,
    bookingInfo,
    handleBookingInfoChange,
    referenceInfo,
    handleReferenceInfoChange,
    availableRooms,
    handleSelectedRoomChange,
    selectedRooms,
    handleFormSubmit,
    handleEditState,
    isLoading,
    isAvailableRoomsLoading,
  } = useBookingForm(isEdit);

  const [queryParams] = useSearchParams();
  const navigate = useNavigate();

  useEffect(() => {
    (async () => {
      if (isEdit && queryParams.get("booking")) {
        const id = +queryParams.get("booking");
        const result = await handleEditState(id);
        if (!result) {
          navigate(ROUTE_PREFIX + "/room-bookings");
        }
      } else if (isEdit && queryParams.get("booking")) {
        navigate(ROUTE_PREFIX + "/room-bookings");
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="shadow rounded bg-white">
      <div className="row justify-content-around align-items-center p-3">
        <div className="col-auto">
          <h5 className="mb-0 fw-bold">{isEdit ? 'Update Booking' : 'Add New Booking'}</h5>
        </div>
      </div>
      <div className="col-8 offset-2">
        <div className="p-3">
          <form>
            <div className="row g-3">
              <div className="col-md-6">
                <label htmlFor="checkInDate" className="form-label">
                  Check In Date<span className="form-field-mandatory">*</span>
                </label>
                <DatePicker
                  // minDate={new Date()}
                  dateFormat="d/M/yyyy"
                  placeholderText="Select Check-in date"
                  id="checkInDate"
                  className="form-control"
                  showIcon
                  selected={bookingInfo.check_in ? new Date(bookingInfo.check_in) : null}
                  onChange={(date) => handleBookingInfoChange('check_in', date)}
                />
              </div>

              <div className="col-md-6">
                <label htmlFor="checkOutDate" className="form-label">
                  Check Out Date<span className="form-field-mandatory">*</span>
                </label>
                <DatePicker
                  minDate={bookingInfo.check_in ? new Date(bookingInfo.check_in) : null}
                  dateFormat="d/M/yyyy"
                  placeholderText="Select Check-out date"
                  id="checkOutDate"
                  className="form-control"
                  showIcon
                  selected={bookingInfo.check_out ? new Date(bookingInfo.check_out) : null}
                  onChange={(date) => handleBookingInfoChange('check_out', date)}
                />
              </div>

              <div className="col-md-4">
                <label htmlFor="adultGuestCount" className="form-label">
                  Number of Adult Guests<span className="form-field-mandatory">*</span>
                </label>
                <input
                  onChange={(e) => handleBookingInfoChange("adult_guest", e.target.value)}
                  type="number"
                  value={bookingInfo.adult_guest || 0}
                  className="form-control"
                  id="adultGuestCount"
                />
              </div>

              <div className="col-md-4">
                <label htmlFor="childGuestCount" className="form-label">
                  Number of Child Guests
                </label>
                <input
                  onChange={(e) => handleBookingInfoChange("child_guest", e.target.value)}
                  type="number"
                  value={bookingInfo.child_guest || 0}
                  className="form-control"
                  id="childGuestCount"
                />
              </div>

              <div className="col-md-4">
                <label htmlFor="selectedRooms" className="form-label">
                  Select Rooms
                </label>
                <Select
                  isMulti
                  isDisabled={isLoading || isAvailableRoomsLoading}
                  options={availableRooms.map((r) => ({ value: r.id, label: r.room_number }))}
                  onChange={handleSelectedRoomChange}
                  value={selectedRooms}
                />
              </div>

              <div className="col-md-6">
                <label htmlFor="totalPrice" className="form-label">
                  Total Price
                </label>
                <input
                  onChange={(e) => handleBookingInfoChange("total_price", e.target.value)}
                  type="number"
                  value={bookingInfo.total_price || 0}
                  className="form-control"
                  id="totalPrice"
                />
              </div>

              {!isEdit && <div className="col-md-6">
                <label htmlFor="currentPayment" className="form-label">
                  Current Payment
                </label>
                <input
                  onChange={(e) => handleBookingInfoChange("paid_amount", e.target.value)}
                  type="number"
                  value={bookingInfo.paid_amount || 0}
                  className="form-control"
                  id="currentPayment"
                />
              </div>}

              <div className="col-12">
                <hr />
              </div>
            </div>

            <h5 className="fw-bold">Primary Contact</h5>

            <div className="row g-3 mb-3">
              <div className="col-md-6">
                <label htmlFor="existing-customer" className="form-label">
                  New Customer?<span className="form-field-mandatory">*</span>
                </label>
                <Select
                  id="existing-customer"
                  options={[
                    { value: true, label: "Yes" },
                    { value: false, label: "No" },
                  ]}
                  onChange={handleIsNewCustomer}
                  value={{ value: isNewCustomer, label: isNewCustomer ? "Yes" : "No" }}
                />
              </div>

              <div className="col-md-6" style={{ display: isNewCustomer ? "none" : "block" }}>
                <label htmlFor="guestId" className="form-label">
                  Select Contact<span className="form-field-mandatory">*</span>
                </label>
                <Select
                  isDisabled={isNewCustomer}
                  id="existing-customer"
                  options={guests.map(({ id, full_name, phone }) => ({ value: id, label: `${full_name} - ${phone}` }))}
                  value={selectedGuest}
                  onChange={handleSelectedGuestChange}
                />
              </div>
            </div>

            <div className="row g-3 mb-3" style={{ display: isNewCustomer ? "flex" : "none" }}>
              <div className="col-md-2">
                <label htmlFor="title" className="form-label">
                  Title<span className="form-field-mandatory">*</span>
                </label>
                <Select
                  id="title"
                  options={[
                    { value: "Mr", label: "Mr" },
                    { value: "Ms", label: "Ms" },
                    { value: "Mrs", label: "Mrs" },
                  ]}
                  onChange={(t) => handleGuestInfoChange("title", t.value)}
                />
              </div>

              <div className="col-md-5">
                <label htmlFor="firstName" className="form-label">
                  First Name<span className="form-field-mandatory">*</span>
                </label>
                <input
                  onChange={(e) => handleGuestInfoChange("first_name", e.target.value)}
                  type="text"
                  className="form-control"
                  id="firstName"
                />
              </div>

              <div className="col-md-5">
                <label htmlFor="lastName" className="form-label">
                  Last Name<span className="form-field-mandatory">*</span>
                </label>
                <input
                  onChange={(e) => handleGuestInfoChange("last_name", e.target.value)}
                  type="text"
                  className="form-control"
                  id="lastName"
                />
              </div>

              <div className="col-md-6">
                <label htmlFor="primaryPhone" className="form-label">
                  Phone<span className="form-field-mandatory">*</span>
                </label>
                <input
                  onChange={(e) => handleGuestInfoChange("phone", e.target.value)}
                  type="tel"
                  className="form-control"
                  id="primaryPhone"
                />
              </div>

              <div className="col-md-6">
                <label htmlFor="primaryEmail" className="form-label">
                  Email<span className="form-field"></span>
                </label>
                <input
                  onChange={(e) => handleGuestInfoChange("email", e.target.value)}
                  type="email"
                  className="form-control"
                  id="primaryEmail"
                />
              </div>

              <div className="col-md-12">
                <label htmlFor="address" className="form-label">
                  Address<span className="form-field-mandatory">*</span>
                </label>
                <input
                  onChange={(e) => handleGuestInfoChange("address", e.target.value)}
                  type="text"
                  className="form-control"
                  id="address"
                />
              </div>
            </div>

            <div className="col-12 mt-5">
                <hr />
            </div>

            <h5 className="fw-bold">Reference</h5>
            <div className="row g-3 mb-3">

              <div className="col-md-6">
                <label htmlFor="referenceName" className="form-label">
                  Reference Name<span className="form-field-mandatory">*</span>
                </label>
                <input
                  onChange={(e) => handleReferenceInfoChange("name", e.target.value)}
                  type="text"
                  className="form-control"
                  id="referenceName"
                  value={referenceInfo?.name || ''}
                />
              </div>

              <div className="col-md-6">
                <label htmlFor="referenceCommission" className="form-label">
                  Commission<span className="form-field"></span>
                </label>
                <input
                  onChange={(e) => handleReferenceInfoChange("commission", e.target.value)}
                  type="number"
                  className="form-control"
                  id="referenceCommission"
                  value={referenceInfo?.commission || 0}
                />
              </div>
            </div>

            <div className="row">
              <div className="col-12">
                <button disabled={isLoading} onClick={handleFormSubmit} type="button" className="btn btn-primary">
                  Submit
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}
