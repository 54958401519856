export const AUTH_REDUCER_SET_AUTH_DATA = "auth_reducer_set_auth_data";
export const AUTH_REDUCER_SET_AUTH_DATA_SUCCESS = "auth_reducer_set_auth_data_success";
export const AUTH_REDUCER_REMOVE_AUTH_DATA = "auth_reducer_remove_auth_data";


export const STORAGE_KEY_AUTH_DATA = "auth_data";

export const TOKEN_STORAGE_KEY = "hms_token";

export const STORAGE_KEY_ROOM_TYPES = "room_types";
export const STORAGE_KEY_HOTEL_INFO = "jo_hotel_info";
// export const API_ENDPOINT = 'http://139.59.118.168:8121/api/v1'
// export const API_ENDPOINT = 'http://localhost:8121/api/v1'
export const API_ENDPOINT = 'https://joreservation.com/api/v1'

export const ROUTE_PREFIX = '/panel';