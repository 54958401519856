/* eslint-disable react-hooks/exhaustive-deps */
import CurrencyFormat from "react-currency-format";
import DataTable from "react-data-table-component";
import useBookingPaymentContext from "../../../hooks/useBookingPaymentContext";
import { useEffect } from "react";
import moment from "moment-timezone";
import DropdownLink from "../../Interface/DropdownLink";

const getPaymentTableCols = (bookingId, invoiceGenerator, paymentEditor) => {
  return [
    {
      name: 'ID',
      selector: (row) => row?.id,
      sortable: true,
    },
    {
      name: 'Amount',
      selector: (row) => <CurrencyFormat prefix="৳" value={+row.amount} suffix="/-" displayType="text" thousandSeparator="," />,
      sortable: true,
    },
    {
      name: 'Date',
      selector: (row) => moment(row?.created_at).format('DD.MM.YYYY'),
      sortable: true,
    },
    {
      name: 'Payment By',
      selector: (row) => row?.user?.name,
      sortable: true,
    },
    {
      name: 'Updated By',
      selector: (row) => row?.payment_history?.[row?.payment_history?.length - 1]?.user?.name || 'N/A',
      sortable: true,
    },
    {
      name: 'Action',
      selector: (row) => <DropdownLink
        actionName={'Edit'}
        actionCallback={() => paymentEditor(row.id)}
        actionName2={'Invoice'}
        actionCallback2={() => invoiceGenerator(bookingId, row.id)}
      />,
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
      sortable: false,
    },
  ];
}

const BookingPaymentHistory = ({ bookingId, setPaymentId }) => {

  const { bookingPayments, isLoading, loadBookingPayments, generatePaymentInvoice } = useBookingPaymentContext();

  useEffect(() => {
    loadBookingPayments(bookingId);
  }, [bookingId]);

  return (
    <div className="row">
      <div className="col-12">
        <DataTable
          columns={getPaymentTableCols(bookingId, generatePaymentInvoice, setPaymentId)}
          progressPending={isLoading}
          data={bookingPayments}
          fixedHeader
          fixedHeaderScrollHeight="60vh"
          highlightOnHover
        />
      </div>
    </div>);
};

export default BookingPaymentHistory;