/* eslint-disable react-hooks/exhaustive-deps */
import { createContext, useState } from "react";
import useAuthContext from '../hooks/useAuthContext';
import { deleteImageByImageId, uploadImageByResource } from "../network/api";

export const ImageContext = createContext();

const ImageContextProvider = (props) => {
  const [isLoading, setIsLoading] = useState(false);
  const { authData } = useAuthContext();

  const uploadImage = async (resourceType, resourceId, image, isMainImage = false) => {
    if (resourceId && authData?.user_data?.hotel?.id) {
      setIsLoading(true);
      const response = await uploadImageByResource(authData.user_data.hotel.id, resourceId, resourceType, image, isMainImage);
      setIsLoading(false);
      return response;
    }
  }

  const deleteImage = async (imageId) => {
    if (authData?.user_data?.hotel?.id) {
      setIsLoading(true);
      const response = await deleteImageByImageId(authData.user_data.hotel.id, imageId);
      setIsLoading(false);
      return response;
    }
  }

  return <ImageContext.Provider value={{
    isLoading,
    uploadImage,
    hotelId: authData?.user_data?.hotel?.id,
    deleteImage
  }}>{props.children}</ImageContext.Provider>
}

export default ImageContextProvider;