import CurrencyFormat from "react-currency-format";
import DropdownLink from "../components/Interface/DropdownLink";
import useRoomContext from "./useRoomContext";
import { ROUTE_PREFIX } from "../common/constants";
import useIsAdmin from "./useIsAdmin";
import { useState } from "react";

const columns = [
  {
    name: "Room Number",
    selector: (row) => row.room_number,
    sortable: true,
  },
  {
    name: "Room Type",
    selector: (row) => row.type,
    sortable: true,
  },
  {
    name: "Rent",
    selector: (row) => <CurrencyFormat prefix="৳" value={+row.rent} suffix="/-" displayType="text" thousandSeparator="," />,
    sortable: true,
  },
  {
    name: "Actions",
    selector: (row) => row.action,
    sortable: true,
    cell: (row) => <DropdownLink firstLink={`${ROUTE_PREFIX}/edit-room?room=${row.id}`} firstLinkName="Edit" />,
    ignoreRowClick: true,
    allowOverflow: true,
    button: true,
  },
];

const useRoomList = () => {

	const { rooms, isLoading, loadRooms, pageMeta } = useRoomContext();
  const [perPage, setPerPage] = useState(pageMeta?.per_page);
  const isAdmin = useIsAdmin();
  const defaultPerPage = 30;
  const onPaginationPageChange = page => {
    loadRooms(page, perPage);
  }

  const onPaginationPerRowsChange = (rowPerPage, page) => {
    setPerPage(rowPerPage);
    loadRooms(page, rowPerPage);
  }

  return {
		columns: isAdmin ? columns : columns.filter(({ name }) => name !== 'Actions'),
    loadRooms,
		isLoading,
    defaultPerPage,
    totalResultCount: pageMeta?.total || 0,
    onPaginationPageChange,
    onPaginationPerRowsChange,
		data: rooms.map(({id, room_number, room_type, price, availability }) => {
			return {
				id,
				room_number,
				type: room_type?.name || 'N/A',
				rent: price,
        availability,
        keyField: id,
			}
		}),
  };
}

export default useRoomList;