/* eslint-disable react-hooks/exhaustive-deps */
import loginBanner from "../../assets/background/login-illustration.svg";
import loginBackground from "../../assets/background/bg-1.svg";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import useAuthContext from "../../hooks/useAuthContext";
import { ROUTE_PREFIX } from "../../common/constants";
// import { AUTH_REDUCER_SET_AUTH_DATA, STORAGE_KEY_AUTH_DATA } from "../../common/constants";



export default function Login() {
  const [formData, setFormData] = useState({email: "", password: ""});
  const { loginFromContext } = useAuthContext();
  const navigate = useNavigate();

  const handleFormData = (e) => {
    setFormData({
      ...formData,
      [e.target.type]: e.target.value
    })
  }

  const handleLoginButton = async () => {
    const { email, password } = formData;
    const data = await loginFromContext({ email, password });
    if (data?.is_logged_in) {
      navigate(ROUTE_PREFIX);
    }
  }

  return (
    <section
      className="vh-100 d-flex justify-content-center"
      style={{
        backgroundImage: `url(${loginBackground})`,
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
      }}
    >
      <div className="d-flex flex-column justify-content-center">
        <div className="row justify-content-center">
          <div className="col-10 col-md-7">
            <div className="shadow bg-white rounded-4 overflow-hidden px-4 py-5">
              <div className="row justify-content-between align-items-center g-5">
                <div className="col d-none d-md-block">
                  <img src={loginBanner} className="img-fluid" alt="" />
                </div>
                <div className="col">
                  <form>
                    <div className="row g-3 justify-content-center">
                      <h2 className="mb-4 fw-bold">Login</h2>

                      <div className="col-12">
                        <label htmlFor="inputEmail" className="form-label visually-hidden">
                          Email
                        </label>
                        <input value={formData.email} onChange={handleFormData} type="email" className="form-control" id="inputEmail4" placeholder="Email" />
                      </div>
                      <div className="col-12">
                        <label htmlFor="inputPassword" className="form-label visually-hidden">
                          Password
                        </label>
                        <input value={formData.password} onChange={handleFormData} type="password" className="form-control" id="inputPassword" placeholder="Password" />
                      </div>
                      <div className="col-12">
                        <div className="row align-items-end">
                          <div className="col-auto">
                            <button onClick={handleLoginButton} type="button" className="btn btn-primary text-uppercase rounded-pill px-4">
                              Login
                            </button>
                          </div>
                          <div className="col-auto">
                            <a href="/forgot-password" className="border-bottom">
                              Forgot Password?
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
