import { Navigate, Outlet } from "react-router-dom";
import useAuthContext from "../../hooks/useAuthContext";
import { getPrefixedRoute } from "../../App";

const AuthOnlyRoute = () => {
    const { authData } = useAuthContext()
    if (!authData?.is_logged_in) {
        return <Navigate to={getPrefixedRoute('/login')} />
    }
    return <Outlet />;
}

export default AuthOnlyRoute;
