import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import AuthContextProvider from "./contexts/AuthContext";
import RoomContextProvider from "./contexts/RoomContext";
import RoomTypeContextProvider from "./contexts/RoomTypeContext";
import GuestContextProvider from "./contexts/GuestContext";
import BookingContextProvider from "./contexts/BookingContext";
import UserContextProvider from "./contexts/UserContext";
import BookingPaymentContextProvider from "./contexts/BookingPaymentContext";
import ImageContextProvider from "./contexts/ImageContext";
import HotelContextProvider from "./contexts/HotelContext";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <AuthContextProvider>
        <HotelContextProvider>
          <RoomTypeContextProvider>
            <RoomContextProvider>
              <BookingContextProvider>
                <GuestContextProvider>
                  <UserContextProvider>
                    <BookingPaymentContextProvider>
                      <ImageContextProvider>
                        <App />
                      </ImageContextProvider>
                    </BookingPaymentContextProvider>
                  </UserContextProvider>
                </GuestContextProvider>
              </BookingContextProvider>
            </RoomContextProvider>
          </RoomTypeContextProvider>
        </HotelContextProvider>
      </AuthContextProvider>
    </BrowserRouter>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
