import moment from 'moment-timezone';
import { useCallback, useEffect, useState } from 'react';
import DatePicker from 'react-datepicker';

const BookingSearch = ({ handleSearchParamsChange, searchParams, isLoading, onSearchStart, onSearchCleared }) => {
    const [checkInDate, setCheckInDate] = useState(null);
    const [checkOutDate, setCheckOutDate] = useState(null);
    const [hasSearchParams, setHasSearchParams] = useState(false);

    useEffect(() => {
        let hasParam = false;
        for (const prop in searchParams) {
            hasParam  = hasParam || Boolean(searchParams[prop]);
        }
        setHasSearchParams(hasParam);
    }, [searchParams]);

    const clearSearchForm = useCallback(() => {
        setCheckInDate(null)
        setCheckOutDate(null)
        onSearchCleared();
    }, [onSearchCleared]);

    return (
            <div className="p-3" style={{ zIndex: 2 }}>
                <form>
                    <div className="row g-3">
                        <div className="col-md-4">
                            <label htmlFor="checkInDate" className="form-label">
                                Check In Date
                            </label>
                            <DatePicker
                                disabled={isLoading}
                                dateFormat="d/M/yyyy"
                                placeholderText="Select Check-in date"
                                id="checkInDate"
                                className="form-control"
                                showIcon
                                selected={checkInDate}
                                onChange={(date) => {
                                    setCheckInDate(date);
                                    handleSearchParamsChange({
                                        ...searchParams,
                                        check_in: moment(date).format('YYYY-MM-DD'),
                                    });
                                }}
                            />
                        </div>

                        <div className="col-md-4">
                            <label htmlFor="checkOutDate" className="form-label">
                                Check Out Date
                            </label>
                            <DatePicker
                                disabled={isLoading}
                                dateFormat="d/M/yyyy"
                                placeholderText="Select Check-out date"
                                id="checkOutDate"
                                className="form-control"
                                showIcon
                                selected={checkOutDate}
                                onChange={(date) => {
                                    setCheckOutDate(date);
                                    handleSearchParamsChange({
                                        ...searchParams,
                                        check_out: moment(date).format('YYYY-MM-DD'),
                                    });
                                }}
                            />
                        </div>
                        <div className="col-md-4">
                            <label htmlFor="roomNumber" className="form-label">
                                Room Number
                            </label>
                            <input
                                type="text"
                                className="form-control"
                                value={searchParams?.room_number}
                                onChange={e => handleSearchParamsChange({
                                    ...searchParams,
                                    room_number: e.target.value,
                                })}
                            />
                        </div>
                        <div className="col-md-12 text-end">
                            {!!hasSearchParams && <button style={{ marginRight: 5 }} disabled={isLoading} onClick={clearSearchForm} type="button" className="btn btn-danger">
                                Clear Search
                            </button>}
                            <button disabled={isLoading} onClick={onSearchStart} type="button" className="btn btn-primary">
                                Search
                            </button>
                        </div>
                    </div>
                </form>
            </div>);
}

export default BookingSearch;