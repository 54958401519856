const CreateRoomTypeForm = ({ handleOnSubmit, handleTypeChange, isLoading }) => {

  return (
    <div className="p-3">
      <form>
        <div className="row g-3">
          <div className="col-md-12">
            <label htmlFor="name" className="form-label">
              Type<span className="form-field-mandatory">*</span>
            </label>
            <input
              className="form-control"
              onChange={(e) => handleTypeChange(e.target.value)}
              type="text"
              id="name"
              placeholder="Enter type name"
            />
          </div>
        </div>
        <div className="row mt-3">
          <div className="col-md-12">
            <button
              disabled={isLoading}
              onClick={() => handleOnSubmit()}
              type="button"
              className="btn btn-primary"
            >
              {isLoading ? 'Creating' : 'Create Type'}
            </button>
          </div>
        </div>
      </form>
    </div>);
}

export default CreateRoomTypeForm;