import { Link } from "react-router-dom";
import BasicDataTable from "../../components/External/BasicDataTable";
import { ROUTE_PREFIX } from "../../common/constants";
import useIsAdmin from "../../hooks/useIsAdmin";

export default function RoomLists() {
  const isAdmin = useIsAdmin();
  return (
    <div className="shadow rounded bg-white">
      <div className="row justify-content-between align-items-center p-3">
        <div className="col-auto">
          <h5 className="mb-0 fw-bold">Room List</h5>
        </div>
        {isAdmin && (<div className="col-auto">
          <Link to={`${ROUTE_PREFIX}/add-room`} type="button" className="btn btn-md btn-primary mb-0 rounded-1">
            Create Room
          </Link>
        </div>)}
        <BasicDataTable />
      </div>
    </div>
  );
}
