/* eslint-disable react-hooks/exhaustive-deps */
import { createContext, useEffect, useState } from "react";
import useAuthContext from '../hooks/useAuthContext';
import { createUser, getUserById, getUserList, getUserRoles, updateUser } from "../network/api";
import useIsAdmin from "../hooks/useIsAdmin";

export const UserContext = createContext();

const UserContextProvider = (props) => {
  const [isLoading, setIsLoading] = useState(false);
  const { authData } = useAuthContext();
  const [users, setUsers] = useState([]);
  const [roles, setRoles] = useState([]);
  const isAdmin = useIsAdmin();

  const loadUsers = async () => {
    if (authData?.user_data?.hotel?.id && isAdmin) {
      setIsLoading(true);
      const usersList = await getUserList(authData?.user_data?.hotel?.id);
      setUsers(usersList?.results || []);
      setIsLoading(false);
    }
  }

  const loadUserById = async (userId) => {
    if (authData?.user_data?.hotel?.id && isAdmin) {
      setIsLoading(true);
      const user = await getUserById(authData?.user_data?.hotel?.id, userId);
      setIsLoading(false);
      return user?.results;
    } else {
      return null;
    }
  }

  const loadRoles = async () => {
    if (authData?.user_data?.hotel?.id) {
      setIsLoading(true);
      const userRoles = await getUserRoles(authData?.user_data?.hotel?.id);
      setRoles(userRoles?.results || []);
      setIsLoading(false);
    }
  }

  useEffect(() => {
    (async () => await loadUsers())();
  }, []);

  useEffect(() => {
    (async () => await loadUsers())(); 
  }, [authData]);

  const storeNewUser = async (userData) => {
    if (!isAdmin) {
      return false;
    }
    setIsLoading(true);
    const response  = await createUser(userData, authData?.user_data?.hotel?.id);
    if (response?.success) {
      await loadUsers();
      setIsLoading(false);
      return true;
    } else {
      setIsLoading(false);
      return false;
    }
  };

  const updateExistingUser = async (userData, userId) => {
    if (!isAdmin) {
      return false;
    }
    setIsLoading(true);
    const response  = await updateUser(userData, authData?.user_data?.hotel?.id, userId);
    if (response?.success) {
      await loadUsers();
      setIsLoading(false);
      return true;
    } else {
      setIsLoading(false);
      return false;
    }
  };

  return <UserContext.Provider
    value={{
      users,
      isLoading,
      loadUsers,
      storeNewUser,
      loadRoles,
      roles,
      loadUserById,
      updateExistingUser,
    }}>{props.children}</UserContext.Provider>
}

export default UserContextProvider;