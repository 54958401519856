/* eslint-disable react-hooks/exhaustive-deps */
import useAuthContext from "./useAuthContext";

const useIsAdmin = () => {
  const { roles } = useAuthContext();
  let isAdmin = true;
  if (!roles || !roles?.find(r => r.name === 'admin')) {
    isAdmin = false;
  }
  return isAdmin;
}

export default useIsAdmin;