/* eslint-disable jsx-a11y/img-redundant-alt */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { Link, useNavigate } from "react-router-dom";
import user_avatar from "../../assets/images/user_avatar.png";
import logo from "../../assets/images/logo.png";
import useAuthContext from '../../hooks/useAuthContext'; 

export default function Topbar() {
  const navigate = useNavigate();
  const {
    authData: {
      is_logged_in = false,
      user_data: {
        name = '',
        roles = [],
        hotel = {},
      } = {}
    } = {},
    logout: contextLogout,
    isLoading,
  } = useAuthContext();

  const logout = async () => {
    await contextLogout();
    navigate('/login');
  }

  return (
    <nav className="navbar-custom nav d-flex justify-content-between align-items-center bg-white px-4 shadow position-fixed top-0 end-0">
      <Link to="/" className="logo-collapsed">
        <img src={logo} alt="" height="40" />
      </Link>
      <span className="fw-bold" style={{ fontSize: 20 }}>{hotel?.name || ''}</span>
      <ul className="list-unstyled d-flex align-items-center mb-0">

        <li className="dropdown notification-list">
          {is_logged_in && name &&
            <a
              className="nav-link dropdown-toggle nav-user me-0 d-flex align-items-center"
              data-bs-toggle="dropdown"
              href="#"
              role="button"
              aria-haspopup="false"
              aria-expanded="false"
            >
              <span className="account-user-avatar me-2">
                <img src={user_avatar} alt="user-image" className="rounded-circle" />
              </span>
              <span className="d-none d-md-block">
                <span className="account-user-name">{name}</span>
                <span className="account-position">{roles[0]?.name?.toUpperCase()}</span>
              </span>
            </a>}
          <div className="dropdown-menu dropdown-menu-end dropdown-menu-animated topbar-dropdown-menu profile-dropdown">
            {/* <a href="#" className="dropdown-item notify-item">
              <i className="mdi mdi-account-circle me-1" />
              <span>My Account</span>
            </a> */}

            {/* <a href="#" className="dropdown-item notify-item">
              <i className="mdi mdi-account-edit me-1" />
              <span>Settings</span>
            </a> */}

            <button disabled={isLoading} type="button" onClick={ () => logout() } className="dropdown-item notify-item">
              <i className="mdi mdi-logout me-1" />
              <span>Logout</span>
            </button>
          </div>
        </li>
      </ul>
    </nav>
  );
}
