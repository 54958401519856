import { useEffect } from "react";

export default function Modal({ show, setShowModal, title, primaryAction, primaryActionText, children, style }) {

  useEffect(() => {
    if (show) {

      document.querySelector('body').classList.add('modal-open');
      document.querySelector('body').style.overflow = 'hidden';
      document.querySelector('body').style.paddingRight = '0px';

      if (!document.querySelector('.modal-backdrop')) {
        const backDrop = document.createElement('div');
        backDrop.classList.add('modal-backdrop', 'fade', 'show');
        document.querySelector('body').appendChild(backDrop);  
      }

    } else {

      document.querySelector('body').classList.remove('modal-open');
      document.querySelector('body').style.removeProperty('overflow');
      document.querySelector('body').style.removeProperty('padding-right');

      if (document.querySelector('.modal-backdrop')) {
        const backdrop = document.querySelector('.modal-backdrop');
        document.querySelector('body').removeChild(backdrop);  
      }

    }
  }, [show]);

  return show ? (
    <div className="modal fade show" style={{ display: 'block' }} id="hmsModal" tabIndex={-1} aria-labelledby="hmsModalLabel" aria-modal="true" role="dialog">
      <div className="modal-dialog modal-dialog-centered" style={{ minWidth: '50%', ...style }}>
        <div className="modal-content border-0">
          <div className="modal-header">
            <h1 className="modal-title fs-4" id="hmsModalLabel">
              {title}
            </h1>
            <button type="button" className="btn-close" onClick={() => setShowModal(false)} aria-label="Close" />
          </div>
          <div className="modal-body">{children}</div>
          <div className="modal-footer">
            <button onClick={() => setShowModal(false)} type="button" className="btn btn-secondary">
              Close
            </button>

            {primaryAction && <button onClick={primaryAction} type="button" className="btn btn-primary">
              {primaryActionText}
            </button>}
          </div>
        </div>
      </div>
    </div>) : null;
}
