// import PageTitle from "../../components/Common/PageTitle";
// import BasicTable from "../../components/Interface/BasicTable";
// import SummaryCard from "../../components/Interface/SummaryCard";
import { useState } from "react";
import AvailableRooms from "./AvailableRooms";
// import AvailabilitySearch from "./AvailabilitySearch";

export default function Dashboard() {
  // eslint-disable-next-line no-unused-vars
  const [showModal, setShowModal] = useState(false);
  return (
    <>
      {/* <PageTitle /> */}
      {/* <section>
        <div className="container px-0">
          <div className="row row-cols-4 g-4">
            <div className="col">
              <SummaryCard />
            </div>

            <div className="col">
              <div className="summary-card card border-0 rounded-1 shadow h-100">
                <div className="card-body p-4">
                  <div className="d-flex justify-content-between">
                    <div>
                      <h5 className="card-title fs-4 fw-bold mb-3">22</h5>
                      <a href="#" className="stretched-link">
                        <h6 className="card-subtitle text-muted">Booked Rooms</h6>
                      </a>
                    </div>
                    <div className="card-icon">
                      <div
                        className="card-icon-holder rounded-circle p-1 d-flex justify-content-center align-items-center"
                        style={{ width: "48px", height: "48px" }}
                      >
                        <i className="bi bi-calendar-check fs-4" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="summary-card card border-0 rounded-1 shadow h-100">
                <div className="card-body p-4">
                  <div className="d-flex justify-content-between">
                    <div>
                      <h5 className="card-title fs-4 fw-bold mb-3">8</h5>
                      <a href="#" className="stretched-link">
                        <h6 className="card-subtitle text-muted">Available Services</h6>
                      </a>
                    </div>
                    <div className="card-icon">
                      <div
                        className="card-icon-holder rounded-circle p-1 d-flex justify-content-center align-items-center"
                        style={{ width: "48px", height: "48px" }}
                      >
                        <i className="bi bi-gear fs-4" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="summary-card card border-0 rounded-1 shadow h-100">
                <div className="card-body p-4">
                  <div className="d-flex justify-content-between">
                    <div>
                      <h5 className="card-title fs-4 fw-bold mb-3">08</h5>
                      <a href="#" className="stretched-link">
                        <h6 className="card-subtitle text-muted">Paid Bookings</h6>
                      </a>
                    </div>
                    <div className="card-icon">
                      <div
                        className="card-icon-holder rounded-circle p-1 d-flex justify-content-center align-items-center"
                        style={{ width: "48px", height: "48px" }}
                      >
                        <i className="bi bi-cash-coin fs-4" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section> */}
      {/* <section className="mt-5">
      <div className="container px-0">
          <div className="row row-cols-2">
            <div className="col">

              <button onClick={() => setShowModal(!showModal)} type="button" className="btn btn-primary">
                Search Available Rooms
              </button>
              <AvailabilitySearch showModal={showModal} setShowModal={setShowModal}/>
            </div>
          </div>
        </div>

      </section> */}

      <section className="mt-3">
        <AvailableRooms />
      </section>
      {/* <section className="mt-5">
        <div className="container px-0">
          <div className="row row-cols-2">
            <div className="col">
              <BasicTable />
            </div>
            <div className="col">
              <BasicTable />
            </div>
          </div>
        </div>
      </section> */}
    </>
  );
}
