import { useState } from "react";
import useRoomTypeContext from "./useRoomTypeContext";
import useRoomContext from "./useRoomContext";
import { useNavigate } from "react-router-dom";
import { ROUTE_PREFIX } from "../common/constants";

const amenities = [
  { value: "ac", label: "AC" },
  { value: "locker", label: "Locker" },
  { value: "geyser", label: "Geyser" },
];
const bedTypes = [
  { value: "queen",label: "Queen" },
  { value: "king",label: "King" },
  { value: "double",label: "Double" },
  { value: "twin",label: "Twin" },
  { value: "single",label: "Single" },
];

const useRoomForm = (isEdit = false) => {
  const { roomTypes: availableRoomTypes, createNewRoomType, isLoading: isRoomTypeLoading } = useRoomTypeContext();
  const { isLoading, storeHotelRoom, loadRoomById, updateRoom } = useRoomContext();
  const navigate = useNavigate();

  const [roomName, setRoomName] = useState('');
  const [roomNumber, setRoomNumber] = useState('');
  const [beds, setBeds] = useState([]);
  const [roomType, setRoomType] = useState({});
  const [adultCapacity, setAdultCapacity] = useState(0);
  const [childCapacity, setChildCapacity] = useState(0);
  const [roomAmenities, setRoomAmenities] = useState([]);
  const [price, setPrice] = useState(0);
  const [roomId, setRoomId] = useState(0);

  const handleRoomNameChange = (newName) => setRoomName(newName);
  const handleRoomNumberChange = (newRoomNumber) => setRoomNumber(newRoomNumber);
  const handleBedDetailsChange = (bedDetails) => setBeds(bedDetails);
  const handleRoomTypeChange = (newType) => setRoomType(newType);
  const handleAdultCapacityChange = (adult) => setAdultCapacity(adult);
  const handleChildCapacityChange = (child) => setChildCapacity(child);
  const handleAmenitiesChange = (amenities) => setRoomAmenities(amenities);
  const handlePriceChange = (newPrice) => setPrice(newPrice);

  const handleEditState = (roomResponse) => {
    const {
      label,
      room_number,
      details: {
        capacity: {
          adult = 0,
          child = 0,
        },
        beds = {},
        itinerary = {},
      },
      room_type_id = 0,
      price = 0,
    } = roomResponse;
    handleRoomNameChange(label);
    handleRoomNumberChange(room_number);
    handlePriceChange(price);
    handleAdultCapacityChange(adult);
    handleChildCapacityChange(child);

    const roomType = availableRoomTypes.find(rt => rt.id === +room_type_id);
    if (roomType && roomType.id) {
      handleRoomTypeChange({ label: roomType.name, value: roomType.id });
    }
    let bedArray = bedTypes
      .filter(bt => beds[bt.value] > 0)
      .map(bt => ({ ...bt, count: beds[bt.value] }))
      .reduce((carry, {value, label, count}) => {
        for(let k=0; k<count; k++)
          carry.push({value, label});
        return carry;
      }, []);
    handleBedDetailsChange(bedArray);
    handleAmenitiesChange(amenities.filter(am => itinerary[am.value] === true));
  }

  const validateFormData = () => {
    let validated = true;
    // if (!roomName) {
    //   validated = false;
    // }
    if (!roomNumber) {
      validated = false;
    }
    if (!Array.isArray(beds) || !beds.length) {
      validated = false;
    }
    if (!roomType || !roomType?.value) {
      validated = false;
    }
    if (!adultCapacity || adultCapacity < 0) {
      validated = false;
    }
    if (childCapacity < 0) {
      validated = false;
    }
    if (!Array.isArray(roomAmenities) || !roomAmenities.length) {
      validated = false;
    }
    if (!price || price < 0) {
      validated = false;
    }
    return validated;
  };

  const handleFormSubmit = async () => {
    if (!validateFormData()) {
      return;
    }
    const transformBeds = bedTypes
                          .map(bed => ({...bed, count: beds.reduce((carry, b) => carry + (b.value === bed.value) ,0)}))
                          .filter(bed => bed.count > 0)
                          .reduce((carry, {value, count}) => ({ ...carry, [value]: count }), {});
    const requestObject = {
      label: roomName,
      room_number: roomNumber,
      room_type_id: +(roomType.value || 0),
      details: {
        beds: transformBeds,
        capacity: {
          adult: +adultCapacity,
          child: +childCapacity,
        },
        itinerary: roomAmenities.reduce((carry, {value}) => ({ ...carry, [value]: true }), {}),
      },
      price: +price,
    };

    const success = isEdit ?
      await updateRoom({...requestObject, id: roomId})
      : await storeHotelRoom(requestObject);

    if (success) {
      navigate(ROUTE_PREFIX + '/room-lists');
    }

  };


  return {
    availableRoomTypes,
		amenities,
    bedTypes,
    roomName,
    roomNumber,
    beds,
    roomType,
    adultCapacity,
    childCapacity,
    roomAmenities,
    price,
    handleRoomNameChange,
    handleRoomNumberChange,
    handleBedDetailsChange,
    handleRoomTypeChange,
    handleAdultCapacityChange,
    handleChildCapacityChange,
    handleAmenitiesChange,
    handlePriceChange,
    handleFormSubmit,
    isLoading,
    isRoomTypeLoading,
    loadRoomById,
    handleEditState,
    updateRoom,
    setRoomId,
    createNewRoomType,
  };
}

export default useRoomForm;