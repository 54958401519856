import moment from 'moment-timezone';
import { useState } from 'react';
import DatePicker from 'react-datepicker';

const AvailabilityForm = ({handleCheckInChange, handleCheckOutChange, isLoading, showDefault = true, minStartDate = null, startPlaceHolder = null, endPlaceHolder = null, startLabel = 'Start date', endLabel = 'End date' }) => {
    const today = new Date();
    const nextDay = new Date(today);
    nextDay.setDate(today.getDate() + 1);
    const [checkInDate, setCheckInDate] = useState(showDefault ? today : '');
    const [checkOutDate, setCheckOutDate] = useState(showDefault ? nextDay : '');

    return (
            <div className="p-3" style={{ zIndex: 2 }}>
                <form>
                    <div className="row g-3">
                        <div className="col-md-6">
                            <label htmlFor="checkInDate" className="form-label">
                                {startLabel || 'Check In Date'}<span className="form-field-mandatory">*</span>
                            </label>
                            <DatePicker
                                minDate={minStartDate || new Date()}
                                disabled={isLoading}
                                dateFormat="d/M/yyyy"
                                placeholderText={startPlaceHolder || "Select Check-in date"}
                                id="checkInDate"
                                className="form-control"
                                showIcon
                                selected={checkInDate}
                                onChange={(date) => {
                                    setCheckInDate(date);
                                    handleCheckInChange(moment(date).format('YYYY-MM-DD'));
                                }}
                            />
                        </div>

                        <div className="col-md-6">
                            <label htmlFor="checkOutDate" className="form-label">
                                {endLabel || 'Check Out Date'}<span className="form-field-mandatory">*</span>
                            </label>
                            <DatePicker
                                minDate={checkInDate}
                                disabled={isLoading || !checkInDate}
                                dateFormat="d/M/yyyy"
                                placeholderText={endPlaceHolder || "Select Check-out date"}
                                id="checkOutDate"
                                className="form-control"
                                showIcon
                                selected={checkOutDate}
                                onChange={(date) => {
                                    setCheckOutDate(date);
                                    handleCheckOutChange(moment(date).format('YYYY-MM-DD'));
                                }}
                            />
                        </div>
                    </div>
                </form>
            </div>);
}

export default AvailabilityForm;