import { useState } from 'react';
import useAvailableRooms from '../../hooks/useAvailableRooms'
import AvailabilityData from './Availibility/AvailabilityData';
import AvailabilityForm from './Availibility/AvailibilityForm';
import moment from 'moment-timezone';

const AvailableRooms = () => {
    const today = moment(new Date()).format('YYYY-MM-DD');
    let tomorrow = new Date();
    tomorrow.setDate(tomorrow.getDate()+1);
    tomorrow = moment(tomorrow).format('YYYY-MM-DD');

    const [checkIn, setCheckIn] = useState(today);
    const [checkOut, setCheckOut] = useState(tomorrow);
    const { columns, data, isAvailableRoomsLoading } = useAvailableRooms(checkIn, checkOut);


    return (
        <>
          <div className="shadow rounded bg-white mb-3">
            <div className="row justify-content-between align-items-center p-3">
              <div className="col-auto">
                <h5 className="mb-0 fw-bold">Search Available Rooms</h5>
              </div>

              {/* <div className="col-auto"> */}
                <AvailabilityForm
                  handleCheckInChange={setCheckIn}
                  handleCheckOutChange={setCheckOut}
                  isLoading={isAvailableRoomsLoading}
                />
              {/* </div> */}
            </div>
          </div>
          <div className="shadow rounded bg-white">
            <div className="row justify-content-between align-items-center p-3">
              <AvailabilityData
                  isLoading={isAvailableRoomsLoading}
                  columns={columns}
                  data={data}
              />
            </div>
          </div>
        </>
      );
}

export default AvailableRooms;