/* eslint-disable react-hooks/exhaustive-deps */
import { createContext, useState } from "react";
import useAuthContext from '../hooks/useAuthContext';
import { generateInvoice, getBookingPayments, storeBookingPayment, updateBookingPayment } from "../network/api";

export const BookingPaymentContext = createContext();

const BookingPaymentContextProvider = (props) => {
  const [isLoading, setIsLoading] = useState(false);
  const { authData } = useAuthContext();
  const hotelId = authData?.user_data?.hotelId;
  const [bookingPayments, setBookingPayments] = useState([]);

  const loadBookingPayments = async (bookingId) => {
    if (authData?.user_data?.hotel?.id && bookingId) {
      setIsLoading(true);
      const payments = await getBookingPayments(authData?.user_data?.hotel?.id, bookingId);
      setBookingPayments(payments?.results || []);
      setIsLoading(false);
    } else {
      setBookingPayments([]);
    }
  };

  const storeNewPayment = async (bookingId, data) => {
    if (authData?.user_data?.hotel?.id && bookingId) {
      setIsLoading(true);
      const success = await storeBookingPayment(authData.user_data.hotel.id, bookingId, data);
      setIsLoading(false);
      if (success) {
        loadBookingPayments(bookingId);
        return true;
      }
    }
    return false;
  };

  const updatePayment = async (bookingId, paymentId, data) => {
    if (authData?.user_data?.hotel?.id && bookingId && paymentId) {
      setIsLoading(true);
      const success = await updateBookingPayment(authData.user_data.hotel.id, bookingId, paymentId, data);
      setIsLoading(false);
      if (success) {
        loadBookingPayments(bookingId);
        return true;
      }
    }
    return false;
  };

  const generatePaymentInvoice = async (bookingId, paymentId = null) => {
    if (authData?.user_data?.hotel?.id && bookingId) {
      await generateInvoice(authData?.user_data?.hotel?.id, bookingId, paymentId ?? null);
    }
  }

  return <BookingPaymentContext.Provider value={{
    isLoading,
    loadBookingPayments,
    bookingPayments,
    setBookingPayments,
    storeNewPayment,
    generatePaymentInvoice,
    hotelId,
    updatePayment
  }}>{props.children}</BookingPaymentContext.Provider>
}

export default BookingPaymentContextProvider;