import { useState } from "react";
import { Link, NavLink } from "react-router-dom";
import logo from "../../assets/images/logo.png";
import { ROUTE_PREFIX } from "../../common/constants";
import useIsAdmin from '../../hooks/useIsAdmin';

export default function Sidebar() {
  const sidebarCollapsed = localStorage.getItem("sidebar-collapsed");
  const [isExpanded, setIsExpanded] = useState(sidebarCollapsed ? false : true);
  const isAdmin = useIsAdmin();

  const handleSidebarToggler = () => {
    if (isExpanded) {
      setIsExpanded(false);
      localStorage.setItem("sidebar-collapsed", true);
      return;
    }
    setIsExpanded(true);
    localStorage.removeItem("sidebar-collapsed");
  };
  return (
    <>
      <div className={isExpanded ? "sidebar-menu" : "sidebar-menu collapsed"}>
        <div className="sidebar-top d-flex align-items-center" style={{ paddingLeft: "30px" }}>
          <button
            className="btn button-toggle-menu p-0 lh-0 border-0q"
            style={{ outline: "none", marginRight: "10px" }}
            onClick={handleSidebarToggler}
          >
            <i className="bi bi-text-left fs-4" />
          </button>
          <Link to={`${ROUTE_PREFIX}`} className="logo">
            <img src={logo} alt="" height="40" />
          </Link>
        </div>

        <div className="h-100" id="leftside-menu-container" data-simplebar="">
          <ul className="side-nav">
            <li className="side-nav-item">
              <NavLink to={`${ROUTE_PREFIX}`} className="side-nav-link">
                <i className="bi bi-grid-1x2" />
                {/* <span className="badge bg-success float-end">4</span> */}
                <span className="sidebar-menu-item">Dashboard</span>
              </NavLink>
            </li>

            <li className="side-nav-item">
              <NavLink to={`${ROUTE_PREFIX}/room-calendar`} className="side-nav-link">
                <i className="bi bi-calendar3" />
                {/* <span className="badge bg-success float-end">4</span> */}
                <span className="sidebar-menu-item">Room Calendar</span>
              </NavLink>
            </li>

            <li className="side-nav-item">
              <NavLink to={`${ROUTE_PREFIX}/room-bookings`} className="side-nav-link">
                <i className="bi bi-calendar2-check" />
                <span className="sidebar-menu-item">Room Bookings</span>
              </NavLink>
            </li>

            <li className="side-nav-item">
              <NavLink to={`${ROUTE_PREFIX}/room-lists`} className="side-nav-link">
                <i className="bi bi-card-checklist" />
                <span className="sidebar-menu-item">Room Lists</span>
              </NavLink>
            </li>
            { isAdmin &&
              <li className="side-nav-item">
                <NavLink to={`${ROUTE_PREFIX}/users`} className="side-nav-link">
                  <i className="bi bi-card-checklist" />
                  <span className="sidebar-menu-item">Users</span>
                </NavLink>
              </li>}
            { isAdmin &&
              <li className="side-nav-item">
                <NavLink to={`${ROUTE_PREFIX}/photos`} className="side-nav-link">
                  <i className="bi bi-images" />
                  <span className="sidebar-menu-item">Photo Gallery</span>
                </NavLink>
              </li>}
            { isAdmin &&
              <li className="side-nav-item">
                <NavLink to={`${ROUTE_PREFIX}/sales`} className="side-nav-link">
                  <i className="bi bi-clipboard-data" />
                  <span className="sidebar-menu-item">Sales</span>
                </NavLink>
              </li>}

            {/* <li className="side-nav-item">
              <NavLink to="/services" className="side-nav-link">
                <i className="bi bi-hdd-stack" />
                <span className="sidebar-menu-item">Services</span>
              </NavLink>
            </li> */}
          </ul>
          <div className="clearfix" />
        </div>
      </div>
    </>
  );
}
