// import { Link } from "react-router-dom";

export default function Footer() {
  return (
    <footer className="footer border-top bg-white px-4 py-1">
      <div className="container-fluid px-0">
        <div className="row justify-content-between">
          <div className="col">
            <p className="mb-0">© {new Date().getFullYear()} joreservation.com</p>
          </div>
          {/* <div className="col">
            <div className="text-end">
              <ul className="list-inline mb-0">
                <li className="list-inline-item">
                  <Link to="/about">About</Link>
                </li>
                <li className="list-inline-item">
                  <Link to="/support">Support</Link>
                </li>
                <li className="list-inline-item">
                  <Link to="/contact">Contact Us</Link>
                </li>
              </ul>
            </div>
          </div> */}
        </div>
      </div>
    </footer>
  );
}
