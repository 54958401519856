import Swal from "sweetalert2";

let toastData = {
	toast: true,
	position: "top-right",
	iconColor: "white",
	showConfirmButton: false,
	timer: 3000,
	timerProgressBar: true,
};

const SuccessToast = Swal.mixin({
	...toastData,
	customClass: {
		popup: "bg-success text-white",
	},
});

const ErrorToast = Swal.mixin({
	...toastData,
	customClass: {
		popup: "bg-danger text-white",
	},
});

const WarningToast = Swal.mixin({
	...toastData,
	customClass: {
		popup: "bg-warning",
	},
});

const InfoToast = Swal.mixin({
	...toastData,
	customClass: {
		popup: "bg-info text-white",
	},
});

const ToastPopup = (type, message) => {
	switch (type) {
		case "success":
			SuccessToast.fire({
				icon: "success",
				title: message,
			});
			break;

		case "error":
			ErrorToast.fire({
				icon: "error",
				title: message,
			});
			break;

		case "warning":
			WarningToast.fire({
				icon: "warning",
				title: message,
			});
			break;

		case "info":
			InfoToast.fire({
				icon: "info",
				title: message,
			});
			break;
		default:
			break;
	}
};

export default ToastPopup;
