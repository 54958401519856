import { Navigate, Outlet } from "react-router-dom";
import useAuthContext from "../../hooks/useAuthContext";
import { getPrefixedRoute } from "../../App";

const GuestRoute = (props) => {
    const { authData } = useAuthContext()
    if (authData?.is_logged_in) {
        return <Navigate to={getPrefixedRoute('/')} />
    }
    return <Outlet />;
}

export default GuestRoute;
