/* eslint-disable react-hooks/exhaustive-deps */
import { createContext, useEffect, useState } from "react";
import useAuthContext from '../hooks/useAuthContext';
import { getHotelInfo } from "../network/api";
import { STORAGE_KEY_HOTEL_INFO } from "../common/constants";

export const HotelContext = createContext();

const getCachedHotelInfo = () => JSON.parse(window.localStorage.getItem(STORAGE_KEY_HOTEL_INFO) || '{}');

const HotelContextProvider = (props) => {
  const [isLoading, setIsLoading] = useState(false);
  const { authData } = useAuthContext();
  const [hotelInfo, setHotelInfo] = useState(getCachedHotelInfo());

  const loadHotelInfo = async (ignoreCache = false) => {
    setIsLoading(true);
    let res = {};
    const existing = JSON.parse(window.localStorage.getItem(STORAGE_KEY_HOTEL_INFO || '{}'));
    if (!ignoreCache && existing && existing?.name) {
      setHotelInfo(existing);
      res = existing;
    }
    if (authData?.user_data?.hotel?.id) {
      const response = await getHotelInfo(authData?.user_data?.hotel?.id);
      window.localStorage.setItem(STORAGE_KEY_HOTEL_INFO, JSON.stringify(response?.results || {}));
      setHotelInfo(response?.results || {});      
      res = response?.results || {};
    }
    setIsLoading(false);
    return res;
  }

  useEffect(() => {
    (async () => await loadHotelInfo(true))();
  }, [authData]);

  return <HotelContext.Provider value={{ isLoading, hotelInfo, loadHotelInfo }}>{props.children}</HotelContext.Provider>
}

export default HotelContextProvider;