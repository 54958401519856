/* eslint-disable jsx-a11y/anchor-is-valid */
import Select from "react-select";
import useRoomForm from "../../hooks/useRoomForm";
import ReactMultiSelectCustom from "../../lib/ReactMultiSelectDuplicate";
import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import CreateTypeModal from "../../components/RoomTypes/CreateTypeModal";
 
export default function AddRoom({ isEdit = false }) {
  const [showRoomTypeModal, setShowRoomTypeModal] = useState(false);
  const { 
          bedTypes,
          availableRoomTypes,
          handleAmenitiesChange,
          handleBedDetailsChange,
          handleRoomNameChange,
          handleRoomNumberChange,
          handleRoomTypeChange,
          handleAdultCapacityChange,
          handleChildCapacityChange,
          handlePriceChange,
          handleFormSubmit,
          loadRoomById,
          handleEditState,
          roomName,
          roomNumber,
          beds,
          roomType,
          adultCapacity,
          childCapacity,
          price,
          amenities,
          roomAmenities,
          setRoomId,
          isRoomTypeLoading,
          createNewRoomType,
          isLoading,
        } = useRoomForm(isEdit);
  const [queryParams] = useSearchParams();

  useEffect(() => {
    (async () => {
      if (isEdit && queryParams.get('room')) {
        const id = +queryParams.get('room');
        const room = await loadRoomById(id);
        if (room) {
          handleEditState(room);
          setRoomId(id);
        }
      }
    })()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="shadow rounded bg-white">

      <div className="row justify-content-between align-items-center p-3">
        <div className="col-auto">
          <h5 className="mb-0 fw-bold">Create Room</h5>
        </div>
      </div>

      <div className="col-8 offset-2">
        <div className="p-3">
          <form className="row gx-5 gy-3">

            <div className="col-md-6">
              <label htmlFor="roomName" className="form-label">
                Room Name<span className="form-field-mandatory">*</span>
              </label>
              <input value={roomName} type="text" className="form-control" id="roomName" onChange={({ target }) => handleRoomNameChange(target.value)} />
            </div>

            <div className="col-md-6">
              <label htmlFor="roomNumber" className="form-label">
                Room Number<span className="form-field-mandatory">*</span>
              </label>
              <input value={roomNumber} type="text" className="form-control" id="roomNumber" onChange={({ target }) => handleRoomNumberChange(target.value)} />
            </div>

            <div className="col-md-6">
              <label htmlFor="bedCountAndType" className="form-label">
                Beds<span className="form-field-mandatory">*</span>
              </label>
              <ReactMultiSelectCustom
                options={bedTypes}
                id="bedCountAndType"
                required={true}
                onChange={handleBedDetailsChange}
                value={beds}
              />
            </div>

            <div className="col-md-6">
              <label className="form-label" htmlFor="roomType">Room Type</label>
              <Select
                options={availableRoomTypes.map(t => ({ label: t.name, value: t.id }))}
                onChange={handleRoomTypeChange}
                value={roomType}
              />
              <a
                onClick={(e) => {
                  e.preventDefault();
                  setShowRoomTypeModal(true);
                }}
                href=""
              >
                Create New Room Type
              </a>
            </div>

            <div className="col-md-6">
              <label htmlFor="adultGuestCount" className="form-label">
                Guest Capacity (Adult)<span className="form-field-mandatory">*</span>
              </label>
              <input
                type="number"
                className="form-control"
                id="adultGuestCount"
                onChange={({target}) => handleAdultCapacityChange(target.value)}
                value={adultCapacity}
              />
            </div>

            <div className="col-md-6">
              <label htmlFor="childGuestCount" className="form-label">
                Guest Capacity (Child)<span className="form-field-mandatory">*</span>
              </label>
              <input
                type="number"
                className="form-control"
                id="childGuestCount"
                onChange={({target}) => handleChildCapacityChange(target.value)}
                value={childCapacity}
              />
            </div>

            <div className="col-md-6">
              <label htmlFor="roomAmenities" className="form-label">
                Amenities<span className="form-field-mandatory">*</span>
              </label>
              <Select
                isMulti
                isClearable={true}
                name="amenities"
                options={amenities}
                className="basic-multi-select"
                classNamePrefix="select"
                id="roomAmenities"
                onChange={handleAmenitiesChange}
                value={roomAmenities}
              />
            </div>

            <div className="col-md-6">
              <label htmlFor="roomRent" className="form-label">
                Price<span className="form-field-mandatory">*</span>
              </label>
              <input
                type="number"
                className="form-control"
                id="roomRent"
                onChange={({target}) => handlePriceChange(target.value)}
                value={price}
              />
            </div>

            <div className="col-12">
              <button disabled={isLoading} type="button" className="btn btn-primary" onClick={handleFormSubmit}>
                Submit
              </button>
            </div>

          </form>
        </div>
      </div>

      <CreateTypeModal
        showModal={showRoomTypeModal}
        setShowModal={setShowRoomTypeModal}
        isLoading={isRoomTypeLoading}
        createNewType={createNewRoomType}
      />

    </div>
  );
}
