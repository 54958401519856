import DataTable from 'react-data-table-component';

const AvailabilityData = ({ columns, isLoading,  data}) => {

    return (
            <div>
              <DataTable
                progressPending={isLoading}
                columns={columns}
                data={data}
                fixedHeader
                fixedHeaderScrollHeight="60vh"
                highlightOnHover />
            </div>);
}

export default AvailabilityData;