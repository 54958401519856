import { createContext, useState } from "react";
import useAuthContext from '../hooks/useAuthContext';
import { getStats } from "../network/api";

export const StatsContext = createContext();

const StatsContextProvider = (props) => {
  const [isLoading, setIsLoading] = useState(false);
  const { authData } = useAuthContext();
  const [stats, setStats] = useState({});

  const loadStats = async (startDate, endDate) => {
    if (authData?.user_data?.hotel?.id && startDate && endDate) {
      setIsLoading(true);
      const response = await getStats(authData?.user_data?.hotel?.id, startDate, endDate);
      setStats(response?.results || {});
      setIsLoading(false);
    }
  }

  return <StatsContext.Provider value={{ stats, isLoading, loadStats }}>{props.children}</StatsContext.Provider>
}

export default StatsContextProvider;
