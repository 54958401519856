import { Navigate, Outlet } from "react-router-dom";
import useIsAdmin from "../../hooks/useIsAdmin";
import { getPrefixedRoute } from "../../App";

const AdminOnlyRoute = () => {
    const isAdmin = useIsAdmin();
    if (!isAdmin) {
        return <Navigate to={getPrefixedRoute('/')} />
    }
    return <Outlet />
}

export default AdminOnlyRoute;
