import { useState } from 'react';
import Modal from '../Interface/Modal';
import CreateRoomTypeForm from './CreateRoomTypeForm';

const CreateTypeModal = ({ showModal, setShowModal, isLoading, createNewType }) => {
  const [type, setType] = useState('');

  const handleTypeChange = (typeName) => {
    setType(typeName);
  }

  const handleOnSubmit = async () => {
    if (type) {
      const r = await createNewType(type);
      if (r) {
        setShowModal(false);
      } else {
        console.error('Something went wrong!');
      }
    }
  }

  return (
    <Modal show={showModal} setShowModal={setShowModal} title={'Create New Room Type'} >
      <div className="shadow rounded bg-white">
        <div className="row justify-content-between align-items-center p-3">
          <div className="col-auto">
            <CreateRoomTypeForm
              handleTypeChange={handleTypeChange}
              handleOnSubmit={handleOnSubmit}
              isLoading={isLoading}
            />
          </div>
        </div>
      </div>
    </Modal>);
}

export default CreateTypeModal;