/* eslint-disable react-hooks/exhaustive-deps */
import { createContext, useEffect, useState } from "react";
import useAuthContext from '../hooks/useAuthContext';
import { createBooking, getAvailableRooms, getBookingById, getBookings, updateBooking } from "../network/api";

export const BookingContext = createContext();
const defaultPageMeta = {
  current_page: 1,
  first_page: 1,
  last_page: 1,
  per_page: 30,
  total: 0,
}

const BookingContextProvider = (props) => {
  const [isLoading, setIsLoading] = useState(false);
  const { authData } = useAuthContext();
  const [bookings, setBookings] = useState([]);
  const [availableRooms, setAvailableRooms] = useState([]);
  const [isAvailableRoomsLoading, setIsAvailableRoomsLoading] = useState(false);
  const [pageMeta, setPageMeta] = useState(defaultPageMeta);

  const loadBookings = async (searchQuery) => {
    if (authData?.user_data?.hotel?.id) {
      setIsLoading(true);
      const bookings = await getBookings(authData?.user_data?.hotel?.id, searchQuery);
      setBookings(bookings?.results || []);
      setPageMeta(bookings?.meta || defaultPageMeta);
      setIsLoading(false);
    }
  }

  const loadAvailableRooms = async (check_in, check_out, booking_id = null) => {
    if (authData?.user_data?.hotel?.id && check_in && check_out) {
      setIsAvailableRoomsLoading(true);
      const rooms = await getAvailableRooms(authData?.user_data?.hotel?.id, check_in, check_out, booking_id);
      setAvailableRooms(rooms?.results || []);
      setIsAvailableRoomsLoading(false);
    } else {
      setAvailableRooms([]);
    }
  };

  const loadBookingById = async (bookingId, options) => {
    setIsLoading(true);
    const response = await getBookingById(authData?.user_data?.hotel?.id, bookingId, options);
    setIsLoading(false);
    return response?.results || {};
  };

  const storeNewBooking = async (bookingData) => {
    setIsLoading(true);
    const response  = await createBooking(bookingData, authData?.user_data?.hotel?.id);
    if (response?.success) {
      await loadBookings();
      setIsLoading(false);
      return true;
    } else {
      console.error('Something went wrong');
      setIsLoading(false);
      return false;
    }
  };

  const updateRoomBooking = async (bookingData) => {
    setIsLoading(true);
    const response  = await updateBooking(bookingData, authData?.user_data?.hotel?.id);
    if (response?.success) {
      await loadBookings();
      setIsLoading(false);
      return true;
    } else {
      setIsLoading(false);
      return false;
    }
  };

  useEffect(() => {
    (async () => await loadBookings())(); 
  }, []);

  useEffect(() => {
    (async () => await loadBookings())(); 
  }, [authData]);

  return <BookingContext.Provider value={{
    bookings,
    isLoading,
    loadBookings,
    availableRooms,
    storeNewBooking,
    loadAvailableRooms,
    isAvailableRoomsLoading,
    pageMeta,
    setIsLoading,
    loadBookingById,
    updateRoomBooking,
  }}>{props.children}</BookingContext.Provider>
}

export default BookingContextProvider;