/* eslint-disable react-hooks/exhaustive-deps */
import { Link } from "react-router-dom";
import DataTable from "react-data-table-component";
import useBookingList from '../../hooks/useBookingList';
import { ROUTE_PREFIX } from "../../common/constants";
import { useState } from "react";
import BookingPaymentModal from "../../components/Modals/BookingPayment/BookingPaymentModal";
import useBookingPaymentContext from "../../hooks/useBookingPaymentContext";
import BookingSearch from "./BookingSearch";

export default function RoomBookings() {
  const [showModal, setShowModal] = useState(false);
  const [bookingIdForPayment, setBookingIdForPayment] = useState(0);
  const setPaymentModal = (id) => {
    setBookingIdForPayment(id);
    setShowModal(true);
  }
  const {
    data,
    columns,
    isLoading,
    searchParams,
    onSearchStart,
    onSearchCleared,
    defaultPerPage,
    setSearchParams,
    totalResultCount,
    onPaginationPageChange,
    onPaginationPerRowsChange
  } = useBookingList(setPaymentModal);
  const { generatePaymentInvoice } = useBookingPaymentContext();

  return (
    <div className="shadow rounded bg-white">
      <div className="row justify-content-between align-items-center p-3">
        <div className="col-auto">
          <h5 className="mb-0 fw-bold">Recent Room Bookings</h5>
        </div>
        <div className="col-auto text-end">
          <div className="row gx-3">
            <div className="col-auto">
              <Link to={`${ROUTE_PREFIX}/room-bookings/add-booking`} type="button" className="btn btn-md btn-primary mb-0 rounded-1">
                New Booking
              </Link>
            </div>
            {/* <div className="col">
              <input type="email" className="form-control form-control-sm rounded-1" id="inputSearch" placeholder="Search" />
            </div> */}
          </div>
        </div>
      </div>
      <BookingSearch
        isLoading={isLoading}
        searchParams={searchParams}
        handleSearchParamsChange={setSearchParams}
        onSearchStart={onSearchStart}
        onSearchCleared={onSearchCleared}
      />
      <div className="row">
        <div className="col-12">
          <DataTable
            columns={columns}
            progressPending={isLoading}
            data={data}
            striped
            // fixedHeader
            fixedHeaderScrollHeight="80vh"
            highlightOnHover
            pagination
            paginationServer
            paginationPerPage={defaultPerPage}
            paginationTotalRows={totalResultCount}
            paginationRowsPerPageOptions={[10, 15, 20, 25, 30, 50]}
            onChangePage={onPaginationPageChange}
            onChangeRowsPerPage={onPaginationPerRowsChange}            
          />
        </div>
      </div>

      <BookingPaymentModal
        invoiceGenerator={() => generatePaymentInvoice(bookingIdForPayment)}
        showModal={showModal}
        setShowModal={setShowModal}
        bookingId={bookingIdForPayment}
      />

    </div>
  );
}
