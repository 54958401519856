import {useEffect, useState } from "react";
import Select from "react-select";

const ReactMultiSelectDuplicate = ({ options, name = '', onChange = null, required = false, id = '', value = [] }) => {
    const [selectedOptions, setSelectedOption] = useState(value.map(b => ({ ...b, av: b.value, value: Math.random() * Math.random() })));

    const handleChange = changedOptions => {
      const newSelectedOption = changedOptions.map(op => ({ ...op, value: Math.random() * Math.random() }));
      setSelectedOption(newSelectedOption);
      if (onChange) {
        onChange(newSelectedOption.map(({label, av: value}) => ( {label, value } )))
      }
    };

    useEffect(() => {
      setSelectedOption(value.map(b => ({ ...b, av: b.value, value: Math.random() * Math.random() })));
    }, [value]);

    return (
        <Select
          isMulti
          hideSelectedOptions={false}
          isClearable={true}
          name={name}
          required={required}
          id={id}
          isSearchable={true}
          value={selectedOptions}
          onChange={handleChange}
          options={options.map(op => ({...op, av: op.value, value: Math.random() * Math.random() }))}
        />
      );
};

export default ReactMultiSelectDuplicate;