/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import useRoomTypeContext from "./useRoomTypeContext";
import ToastPopup from "../common/ToastPopup";
import useImageContext from "./useImageContext";
import useHotelContext from "./useHotelContext";

export const MAXIMUM_IMAGE_PER_ROOM_TYPE = 5;
export const RESOURCE_TYPE_ROOM_TYPE = 'resourceType';
export const RESOURCE_TYPE_HOTEL = 'hotel';

const useImageForm = (isEdit = false) => {
  const { roomTypes: availableRoomTypes, isLoading: isRoomTypeLoading, loadRoomTypes } = useRoomTypeContext();
  const { uploadImage, isLoading: isUploading, hotelId, deleteImage } = useImageContext();
  const { isLoading: isHotelLoading, hotelInfo, loadHotelInfo } = useHotelContext();

  const [selectedResource, setSelectedResource] = useState({});
  const [selectedImage, setSelectedImage] = useState(null);
  const [images, setImages] = useState([]);
  const [isMainHotelImage, setIsMainHotelImage] = useState(true);

  useEffect(() => {
    let photos = [];
    switch (selectedResource?.resourceType) {
      case RESOURCE_TYPE_ROOM_TYPE:
        photos = availableRoomTypes?.find(t => t.id === Number(selectedResource.value))?.images || [];
        break;
      case RESOURCE_TYPE_HOTEL:
        photos = hotelInfo?.images || [];
        break;
      default:
        break;
    }
    setImages(photos);

  }, [availableRoomTypes, selectedResource, hotelInfo]);

  useEffect(() => {
    loadHotelInfo(true);
  }, []);

  const handleResourceChange = (resource) => {
    if (resource.resourceType === RESOURCE_TYPE_HOTEL) {
      resource = {
        ...resource,
        value: hotelId,
      };
    }
    setSelectedResource(resource);
    const imageInput = document.querySelector('#imageInput');
    if (imageInput) {
      imageInput.value = "";
    }
    setSelectedImage(null);
  }
  const handleFileChange = (file) => setSelectedImage(file);
  const handleMainHotelImageSelection = (checked) => {
    setIsMainHotelImage(checked);
  }

  const refreshResource = async () => {
    switch (selectedResource?.resourceType) {
      case RESOURCE_TYPE_ROOM_TYPE:
        await loadRoomTypes(true);
        break;
      case RESOURCE_TYPE_HOTEL:
        await loadHotelInfo(true);
        break;
      default:
        break;
    }

  }

  const handleImageUpload = async () => {
    if (selectedResource.value && selectedResource.resourceType) {
      if (images?.length >= MAXIMUM_IMAGE_PER_ROOM_TYPE) {
        ToastPopup('warning', `Sorry! Cannot upload more than ${MAXIMUM_IMAGE_PER_ROOM_TYPE} images.`);
        return;
      }
      const response = await uploadImage(selectedResource.resourceType, selectedResource.value, selectedImage, Number(isMainHotelImage));
      ToastPopup(response?.success ? 'info' : 'error', response?.message || 'Sorry!, Could not upload the image.');
      if (response?.success) {
        document.querySelector('#imageInput').value = "";
        refreshResource();
        setSelectedImage(null);
      }      
    }
  }

  const handleImageDelete = async (imageId) => {
    const hasImage = images.findIndex(image => image?.id === imageId) >= 0;
    if (hasImage) {
      const response = await deleteImage(imageId);
      ToastPopup(response?.success ? 'info' : 'error', response?.message || 'Sorry!, Could not delete the image.');
      if (response?.success) {
        refreshResource();
      }
    }
  }

  return {
    availableRoomTypes,
    isRoomTypeLoading,
    isHotelLoading,
    handleImageUpload,
    handleFileChange,
    selectedImage,
    isUploading,
    handleResourceChange,
    hotelId,
    selectedResource,
    images,
    handleImageDelete,
    isMainHotelImage,
    handleMainHotelImageSelection,
    hotelInfo,
  };
}

export default useImageForm;