/* eslint-disable jsx-a11y/anchor-is-valid */
import { useEffect } from "react";

const BookingPaymentForm = ({ formData }) => {
  const {
    amount = 0,
    isLoading,
    handleAmountChange,
    handleFormSubmit,
    getPaymentById,
    paymentId,
    setPaymentId,
  } = formData;

  useEffect(() => {
    if (paymentId) {
      const p = getPaymentById(paymentId);
      handleAmountChange(p?.amount || 0);
    } else {
      handleAmountChange(0);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paymentId]);

  return (
    <div className="p-3">
      <form>
        <div className="row g-3">
          <div className="col-md-6">
            <label htmlFor="amount" className="form-label">
              {paymentId ? `Update payment (#${paymentId})` : 'Receive New Payment'}<span className="form-field-mandatory">*</span>
            </label>
            <input
              className="form-control"
              onChange={(e) => handleAmountChange(e.target.value)}
              type="number"
              id="amount"
              value={amount}
              placeholder="Amount"
              disabled={isLoading}
            />
          </div>
        </div>
        {!!paymentId && (
          <div className="row mt-2">
            <div className="col">
              <a
                disabled={isLoading}
                href="#"
                onClick={(e) => { e.preventDefault(); setPaymentId(0)}}
              >
                {'Clear Form'}
              </a>
            </div>
          </div>
        )}
        <div className="row mt-2">
          <div className="col">
            <button
              disabled={isLoading}
              onClick={() => handleFormSubmit()}
              type="button"
              className="btn btn-primary"
            >
              {isLoading ? (paymentId ? 'Updating' : 'Saving') : (paymentId ? 'Update' : 'Save')}
            </button>
          </div>
        </div>
      </form>
    </div>);
}

export default BookingPaymentForm;