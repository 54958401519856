/* eslint-disable jsx-a11y/anchor-is-valid */
import { Link } from "react-router-dom";

export default function DropdownLink({ firstLink, firstLinkName, secondLink, secondLinkName, actionName, actionCallback, actionName2, actionCallback2 }) {
  return (
    <div className="dropdown">
      <a className="btn btn-sm btn-secondary dropdown-toggle" href="" role="button" data-bs-toggle="dropdown" aria-expanded="false">
        Options
      </a>

      <ul className="dropdown-menu">
      {firstLink && firstLinkName && <li>
          <Link to={firstLink} className="dropdown-item small">
            {firstLinkName}
          </Link>
        </li>}

        {secondLink && secondLinkName && <li>
          <Link to={secondLink} className="dropdown-item small">
            {secondLinkName}
          </Link>
        </li>}

        {!!actionName && !!actionCallback && <li>
          <Link to={''} onClick={(e) => {e.preventDefault(); actionCallback()}} className="dropdown-item small">
            {actionName}
          </Link>
        </li>}
        {!!actionName2 && !!actionCallback2 && <li>
          <Link to={''} onClick={(e) => {e.preventDefault(); actionCallback2()}} className="dropdown-item small">
            {actionName2}
          </Link>
        </li>}
      </ul>
    </div>
  );
}
