/* eslint-disable react-hooks/exhaustive-deps */
import { createContext, useEffect, useState } from "react";
import useAuthContext from '../hooks/useAuthContext';
import { createHotelRoom, getHotelRooms, getRoomById, getRoomCalendar, updateHotelRoom } from "../network/api";

export const RoomContext = createContext();
const defaultPageMeta = {
  current_page: 1,
  first_page: 1,
  last_page: 1,
  per_page: 30,
  total: 0,
}
const RoomContextProvider = (props) => {
  const [isLoading, setIsLoading] = useState(false);
  const { authData } = useAuthContext();
  const [rooms, setRooms] = useState([]);
  const [pageMeta, setPageMeta] = useState(defaultPageMeta);
  const [roomCalendar, setRoomCalendar] = useState([]);

  const loadRooms = async (page = 1, perPage = 30) => {
    if (authData?.user_data?.hotel?.id) {
      setIsLoading(true);
      const hotelRooms = await getHotelRooms(authData?.user_data?.hotel?.id, page, perPage);
      setRooms(hotelRooms?.results || []);
      setPageMeta(hotelRooms?.meta || defaultPageMeta);
      setIsLoading(false);
    }
  }

  const loadRoomCalendar = async () => {
    if (authData?.user_data?.hotel?.id) {
      setIsLoading(true);
      const calendar = await getRoomCalendar(authData?.user_data?.hotel?.id);
      setRoomCalendar(calendar?.results || []);
      setIsLoading(false);
    }
  }
  
  const storeHotelRoom = async (hotelData) => {
    setIsLoading(true);
    const response  = await createHotelRoom(hotelData, authData?.user_data?.hotel?.id);
    if (response?.success) {
      await loadRooms();
      setIsLoading(false);
      return true;
    } else {
      setIsLoading(false);
      return false;
    }
  };

  const updateRoom = async (roomData) => {
    setIsLoading(true);
    const response  = await updateHotelRoom(roomData, authData?.user_data?.hotel?.id);
    if (response?.success) {
      await loadRooms();
      setIsLoading(false);
      return true;
    } else {
      setIsLoading(false);
      return false;
    }
  };

  const loadRoomById = async (roomId) => {
    setIsLoading(true);
    const response = await getRoomById(authData?.user_data?.hotel?.id, roomId);
    setIsLoading(false);
    return response?.results || {};
  }

  
  useEffect(() => {
    (async () => await loadRooms())(); 
  }, []);

  useEffect(() => {
    (async () => await loadRooms())(); 
  }, [authData]);

  return <RoomContext.Provider value={{
    rooms,
    isLoading,
    loadRooms,
    storeHotelRoom,
    loadRoomById,
    updateRoom,
    pageMeta,
    loadRoomCalendar,
    roomCalendar,
  }}>{props.children}</RoomContext.Provider>
}

export default RoomContextProvider;