// Import SCSS
import "./scss/theme.scss";
// Import Bootstrap JS
import "../node_modules/bootstrap/dist/js/bootstrap.bundle";

import { Navigate, Route, Routes } from "react-router-dom";
import Dashboard from "./pages/Dashboard/Dashboard";
import RoomBookings from "./pages/RoomBookings/RoomBookings";
import GuestRoute from "./pages/Authentication/GuestRoute";
import AuthOnlyRoute from "./pages/Authentication/AuthOnlyRoute";
import AdminOnlyRoute from "./pages/Authentication/AdminOnlyRoute";
import Sidebar from "./components/Common/Sidebar";
import Topbar from "./components/Common/Topbar";
import Footer from "./components/Common/Footer";
import RoomLists from "./pages/RoomLists/RoomLists";
// import Services from "./pages/Services/Services";
import AddBooking from "./pages/RoomBookings/AddBooking";
import AddRoom from "./pages/RoomLists/AddRoom";
import Login from "./pages/Authentication/Login";
import { ROUTE_PREFIX } from "./common/constants";
import Users from "./pages/Users/Users";
import CreateUser from "./pages/Users/CreateUser";
import StatsContextWrapper from "./pages/Stats/StatsContextWrapper";
import RoomCalendar from "./pages/RoomLists/RoomCalendar";
import PhotoGallery from "./pages/PhotoGallery/PhotoGallery";

const mainTemplate = (component) => {
  return (
    <>
      <Sidebar />
      <main className="main-layout position-relative px-4" style={{ backgroundColor: "#F5F5F7" }}>
        <div className="container px-0 mb-5">
          <Topbar />
          {component}
        </div>
      </main>
      <Footer />
    </>
  );
};

export const getPrefixedRoute = (route) => ROUTE_PREFIX + route;

function App() {
  return (
    <>
      <Routes>
        <Route element={<AuthOnlyRoute />}>
          <Route path={getPrefixedRoute('/')} element={mainTemplate(<Dashboard />)} />
          <Route path={getPrefixedRoute('/room-bookings')} element={mainTemplate(<RoomBookings />)} />
          <Route path={getPrefixedRoute('/room-lists')} element={mainTemplate(<RoomLists />)} /> 
          <Route path={getPrefixedRoute('/room-bookings/add-booking')} element={mainTemplate(<AddBooking />)} />
          <Route path={getPrefixedRoute('/room-bookings/edit-booking')} element={mainTemplate(<AddBooking isEdit={true} />)} />
          <Route path={getPrefixedRoute('/room-calendar')} element={mainTemplate(<RoomCalendar />)} />
        </Route>

        <Route element={<AdminOnlyRoute />}>
          {/* <Route path={getPrefixedRoute('/room-details')} element={mainTemplate(<RoomDetails />)} /> */}
          <Route path={getPrefixedRoute('/add-room')} element={mainTemplate(<AddRoom />)} />
          <Route path={getPrefixedRoute('/edit-room')} element={mainTemplate(<AddRoom isEdit={true} />)} />
          <Route path={getPrefixedRoute('/users')} element={mainTemplate(<Users />)} />
          <Route path={getPrefixedRoute('/add-user')} element={mainTemplate(<CreateUser />)} />
          <Route path={getPrefixedRoute('/users/edit-user')} element={mainTemplate(<CreateUser isEdit={true} />)} />
          <Route path={getPrefixedRoute('/photos')} element={mainTemplate(<PhotoGallery />)} />
          <Route path={getPrefixedRoute('/sales')} element={mainTemplate(<StatsContextWrapper />)} />
        </Route>

        <Route element={<GuestRoute />}>
          <Route path={getPrefixedRoute('/login')} element={<Login />} />      
        </Route>
        <Route path="*" element={<Navigate to={getPrefixedRoute('/login')} replace />} />
      </Routes>
    </>
  );
}

export default App;
