/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import Select from "react-select";
import useUserForm from "../../hooks/useUserForm";
import { useEffect } from "react";
import { useSearchParams } from "react-router-dom";
 
export default function CreateUser({ isEdit = false }) {
  const {
    loadUserById,
    handleNameChange,
    handleEmailChange,
    handlePhoneChange,
    handlePasswordChange,
    handleRoleChange,
    handleFormSubmit,
    handleEditState,
    setUserId,
    isLoading,
    roles,
    name,
    email,
    phone,
    password,
    role,
  } = useUserForm();
  const [queryParams] = useSearchParams();
  useEffect(() => {
    (async () => {
      if (isEdit && queryParams.get('user')) {
        const id = +queryParams.get('user');
        const user = await loadUserById(id);
        if (user) {
          handleEditState(user);
          setUserId(id);
        }
      }
    })();
  }, []);

  return (
    <div className="shadow rounded bg-white">

      <div className="row justify-content-between align-items-center p-3">
        <div className="col-auto">
          <h5 className="mb-0 fw-bold">{ isEdit ? 'Update Existing User' : 'Add New User'}</h5>
        </div>
      </div>

      <div className="col-8 offset-2">
        <div className="p-3">
          <form className="row gx-5 gy-3">

            <div className="col-md-6">
              <label htmlFor="name" className="form-label">
                Name<span className="form-field-mandatory">*</span>
              </label>
              <input
                disabled={isLoading}
                value={name}
                type="text"
                className="form-control"
                id="name"
                onChange={({ target }) => handleNameChange(target.value)}
              />
            </div>

            <div className="col-md-6">
              <label htmlFor="email" className="form-label">
                Email<span className="form-field-mandatory">*</span>
              </label>
              <input
                disabled={isLoading}
                value={email}
                type="email"
                className="form-control"
                id="email"
                onChange={({ target }) => handleEmailChange(target.value)}
              />
            </div>

            <div className="col-md-6">
              <label htmlFor="phone" className="form-label">
                Phone<span className="form-field-mandatory">*</span>
              </label>
              <input
                disabled={isLoading}
                value={phone}
                type="phone"
                className="form-control"
                id="phone"
                onChange={({ target }) => handlePhoneChange(target.value)}
              />
            </div>

            <div className="col-md-6">
              <label htmlFor="password" className="form-label">
                Password<span className="form-field-mandatory">*</span>
              </label>
              <input
                disabled={isLoading}
                value={password}
                min={8}
                type="password"
                className="form-control"
                id="password"
                onChange={({ target }) => handlePasswordChange(target.value)}
              />
              {password?.trim()?.length < 8 ? <span style={{color: 'red'}}>Minimum 8 characters</span> : ''}<br />
              {isEdit ? <span style={{color: 'red'}}>DO NOT Enter anything if you don't want to update the password</span> : ''}
            </div>


            <div className="col-md-6">
              <label className="form-label" htmlFor="roomType">User Role</label>
              <Select
                isDisabled={isLoading}
                options={roles?.filter(({name}) => name !== 'api-user').map(r => ({ label: r.name.toUpperCase(), value: r.id })) || []}
                onChange={handleRoleChange}
                value={role}
              />
            </div>

            <div className="col-12">
              <button disabled={isLoading} type="button" className="btn btn-primary" onClick={handleFormSubmit}>
                Submit
              </button>
            </div>

          </form>
        </div>
      </div>

    </div>
  );
}
