/* eslint-disable react-hooks/exhaustive-deps */
import { useState } from "react";
import useBookingPaymentContext from "./useBookingPaymentContext";

const usePaymentForm = (bookingId) => {
  const { isLoading, storeNewPayment, bookingPayments, updatePayment } = useBookingPaymentContext();
  const [amount, setAmount] = useState(0);
  const [paymentId, setPaymentId] = useState(0);
 
  const handleAmountChange = (newAmount) => {
    setAmount(newAmount);
  }

  const handleFormSubmit = async () => {
    if (!amount) {
      return;
    }
    if (paymentId && getPaymentById(paymentId)?.amount === amount) {
      return false;
    }

    const requestObject = {
      amount
    };
    const success = paymentId ?
      await updatePayment(bookingId, paymentId, requestObject) : await storeNewPayment(bookingId, requestObject);
    if (success) {
      setAmount(0);
      setPaymentId(0);
    }
  };

  const getPaymentById = (id) => {
    return bookingPayments?.find(p => p.id === id);
  }

  return {
    amount,
    isLoading,
    handleAmountChange,
    handleFormSubmit,
    getPaymentById,
    paymentId,
    setPaymentId,
  };
}

export default usePaymentForm;