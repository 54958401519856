import useRoomContext from "./useRoomContext";
import moment from "moment-timezone";


const generateColumns = (columns) => {

  const cols = [];
  // cols.push({
  //   name: 'Room',
  //   selector: (row) => row.room_number,
  //   sortable: false,
  // });

  for (const key in columns) {
    if (key === 'room_number') {
      continue;
    }
    const col = {
      name: moment(key).format('DD-MM'),
      selector: (row) => <><i style={{ color: row[key] ? 'green' : 'red' }} className={`bi bi-${row[key] ? 'check-lg' : 'x-lg'}`}></i>{' '}<b>{row.room_number}</b></>,
      sortable: false,
    };
    cols.push(col);
  }

  return cols;
}

const useRoomCalendar = () => {

	const { isLoading, loadRoomCalendar, roomCalendar } = useRoomContext();
  
  return {
		columns: generateColumns(roomCalendar[0]),
    loadRoomCalendar,
		isLoading,
		data: roomCalendar,
  };
}

export default useRoomCalendar;