import { createContext, useState } from "react";
import { processLoginOperation, processLogoutOperation } from "../reducers/AuthReducer";
import { STORAGE_KEY_AUTH_DATA } from "../common/constants";

export const AuthContext = createContext();


function getAuthData() {
  return JSON.parse(window.localStorage.getItem(STORAGE_KEY_AUTH_DATA));
}

const defaultAuthData = {
  is_logged_in: false,
  user_data: {},
};
const AuthContextProvider = (props) => {

  const [authData, setAuthData] = useState(getAuthData() || defaultAuthData);
  const {
    user_data: {
      roles = []
    } = {}
  } = authData;
  const [isLoading, setIsLoading] = useState(false);

  const loginFromContext = async credentials => {
    setIsLoading(true);
    const data = await processLoginOperation(credentials);
    setAuthData(data);
    setIsLoading(false);
    return data;
  }

  const logout = async () => {
    setIsLoading(true);
    await processLogoutOperation();
    setAuthData(defaultAuthData);
    setIsLoading(false);
  } 
  return <AuthContext.Provider value={{ authData, isLoading, loginFromContext, roles, logout }}>{props.children}</AuthContext.Provider>
}

export default AuthContextProvider;