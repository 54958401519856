/* eslint-disable react-hooks/exhaustive-deps */
import CurrencyFormat from "react-currency-format";
import AvailabilityForm from "../Dashboard/Availibility/AvailibilityForm";
import useStatsContext from "../../hooks/useStatsContext";
import { useEffect, useState } from "react";
import moment from "moment-timezone";
export default function Stats() {

  const { 
    stats: {
      totalRent = null,
      totalCommission = null,
      totalPayments = null,
    },
    loadStats = null,
    isLoading = false,
  } = useStatsContext();
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  useEffect(() => {
    if (startDate && endDate && startDate <= endDate) {
      loadStats(startDate, endDate);
    }
  }, [startDate, endDate])

  const handleStartDateChange = (startDate) => {
    if (startDate) {
      setStartDate(startDate);
    }
  }

  const handleEndDateChange = (endDate) => {
    if (endDate) {
      setEndDate(endDate);
    }
  }

  return (
    <>
      <div className="shadow rounded bg-white">
        <div className="row justify-content-between align-items-center p-3">
          <div className="col-auto">
            <h5 className="mb-0 fw-bold">Sales Stats</h5>
          </div>
          {/* <div className="col-auto text-end">
            <div className="row gx-3">
              <div className="col-auto">
                <Link to={`${ROUTE_PREFIX}/room-bookings/add-booking`} type="button" className="btn btn-md btn-primary mb-0 rounded-1">
                  New Booking
                </Link>
              </div> */}
              {/* <div className="col">
                <input type="email" className="form-control form-control-sm rounded-1" id="inputSearch" placeholder="Search" />
              </div> */}
            {/* </div>
          </div> */}
        </div>

        <div className='row'>
          <div className='col-12'>
            <AvailabilityForm
              handleCheckInChange={handleStartDateChange}
              handleCheckOutChange={handleEndDateChange}
              isLoading={isLoading}
              showDefault={false}
              minStartDate={moment().subtract(5, 'years').toDate()}
              startPlaceHolder={'Select a start date'}
              endPlaceHolder={'Select an end date'}
              startLabel={'Start date'}
              endLabel={'End date'}
            />
          </div>
        </div>
      </div>

      <div className="mt-3">
        <div className="row justify-content-between align-items-center p-3">
          <div className="col-4 shadow rounded bg-white p-3">
            <h5>Total Rent</h5>
            <h6>
              { isLoading ? '...' : (
                totalRent !== null ?
                  <CurrencyFormat prefix="৳" value={totalRent} suffix="/-" displayType="text" thousandSeparator="," /> :
                  'N/A')
              }
            </h6>
          </div>

          <div className="col-3 shadow rounded bg-white p-3">
            <h5>Total Commission</h5>
            <h6>
              { isLoading ? '...' : (
                totalCommission !== null ?
                  <CurrencyFormat prefix="৳" value={totalCommission} suffix="/-" displayType="text" thousandSeparator="," /> :
                  'N/A')
              }
            </h6>
          </div>
          <div className="col-4 shadow rounded bg-white p-3">
            <h5>Total Payments</h5>
            <h6>
              {
                isLoading ? '...' : (
                totalPayments !== null ?
                  <CurrencyFormat prefix="৳" value={totalPayments} suffix="/-" displayType="text" thousandSeparator="," /> :
                  'N/A')
              }
            </h6>
            {/* <p style={{ color: 'red' }}>* After paying commission</p> */}
          </div>
        </div>
      </div>
    </>
  );
}
