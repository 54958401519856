/* eslint-disable react-hooks/exhaustive-deps */
import DataTable from "react-data-table-component";
import useRoomCalendar from "../../hooks/useRoomCalendar";
import { useEffect } from "react";

export default function RoomCalendar() {

  const {
    columns,
    data,
    isLoading,
    loadRoomCalendar,
  } = useRoomCalendar();

  useEffect(function() {
    loadRoomCalendar();
  }, []);

  return (
    <div className="shadow rounded bg-white">
      <div className="row justify-content-between align-items-center p-3">
        <div className="col-auto">
          <h5 className="mb-0 fw-bold">Room Calendar</h5>
        </div>
        <div className="row">
          <div className="col-12">
            <DataTable
              columns={columns}
              progressPending={isLoading}
              data={data}
              striped
              fixedHeader
              dense={true}
              fixedHeaderScrollHeight="80vh"
              highlightOnHover
            />
          </div>
        </div>

      </div>
    </div>
  );
}
