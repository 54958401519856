// import DropdownLink from "../components/Interface/DropdownLink";
// import { ROUTE_PREFIX } from "../common/constants";
import { ROUTE_PREFIX } from "../common/constants";
import DropdownLink from "../components/Interface/DropdownLink";
import useUserContext from "./useUserContext";
const columns = [
    {
      name: "ID",
      selector: (row) => `#${row.id}`,
      sortable: true,
    },
    {
      name: "Name",
      selector: (row) => row.name,
      sortable: false,
    },
    {
      name: "Email",
      selector: (row) => row.email,
      sortable: true,
    },
    {
      name: "Phone",
      selector: (row) => row.phone,
      sortable: true,
    },
    {
      name: "Roles",
      selector: (row) => row.roles?.map(({ name = '' }) => name.toUpperCase())?.join(','),
      sortable: true,
    },
    {
      name: "Actions",
      selector: (row) => row?.action,
      sortable: true,
      cell: (row) => <DropdownLink firstLink={`${ROUTE_PREFIX}/users/edit-user?user=${row.id}`} firstLinkName="Edit" />,
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },
  ];

const useUsersList = () => {

	const { users, loadUsers, isLoading  } = useUserContext();

  return {
		columns: columns,
		loadUsers,
		isLoading,
		data: users,
  };
}

export default useUsersList;