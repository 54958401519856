import axios from "axios";

import { API_ENDPOINT, STORAGE_KEY_AUTH_DATA, STORAGE_KEY_ROOM_TYPES, TOKEN_STORAGE_KEY } from "../common/constants";


const getBaseConfig = (isAuth, extraHeaders = {}, content = "application/json") => {
	const authHeader = isAuth
		? { Authorization: `Bearer ${localStorage.getItem(TOKEN_STORAGE_KEY)}` }
		: {};
	return {
		baseURL: API_ENDPOINT,
		timeout: 30000,
		headers: {
			Accept: "application/json",
			"Content-type": content,
			...authHeader,
			...extraHeaders,
			// 'Access-Control-Allow-Origin': '*',
		},
	};
};

const makeNetworkRequest = async (isAuth, url, method, body, query = {}, headers = {}) => {
	const baseConfig = getBaseConfig(isAuth, headers);

	const config = {
		...baseConfig,
		url,
		method,
		data: body,
		params: query,
	};
	try {
		return await (
			await axios.request(config)
		)?.data;
	} catch (error) {
		if (error?.response?.status === 401) {
			//detect unauthorized response

			window.localStorage.removeItem(STORAGE_KEY_AUTH_DATA);
			window.localStorage.removeItem(TOKEN_STORAGE_KEY);
			window.localStorage.removeItem(STORAGE_KEY_ROOM_TYPES);
			window.dispatchEvent(new Event("storage"));
		}

		return error?.response?.data ?? null;
	}
};

const makeFileRequest = async (isAuth, url, method, body, headers = {}) => {
	const baseConfig = getBaseConfig(isAuth, headers);

	const config = {
		...baseConfig,
		url,
		method,
		data: body,
		responseType: 'blob',
	};
	try {
		const res = await axios.request(config);
		const url = URL.createObjectURL(res.data);
		const a = document.createElement('a');
		a.style = 'display: none';
		a.href = url;
		a.target = '_blank';
		document.body.appendChild(a);
		a.click();
		document.body.removeChild(a);
		URL.revokeObjectURL(url);
	} catch (error) {
		if (error?.response?.status === 401) {
			//detect unauthorized response

			window.localStorage.removeItem(STORAGE_KEY_AUTH_DATA);
			window.localStorage.removeItem(TOKEN_STORAGE_KEY);
			window.localStorage.removeItem(STORAGE_KEY_ROOM_TYPES);
			window.dispatchEvent(new Event("storage"));
		}
		return error?.response?.data ?? null;
	}
};

export const login = async (email, password) => {
	return await makeNetworkRequest(false, "/panel/login", "POST", {
		email,
		password,
	});
};

export const getHotelInfo = async (hotelId) => {
	return await makeNetworkRequest(true, `/hotels/${hotelId}`, "GET");
};

export const getHotelRooms = async (hotelId, page = 1, perPage = 30) => {
	return await makeNetworkRequest(true, `/hotels/${hotelId}/rooms?page=${page}&perPage=${perPage}`, "GET");
};

export const getRoomCalendar = async (hotelId) => {
	return await makeNetworkRequest(true, `/hotels/${hotelId}/room-calendar`, "GET");
};

export const createHotelRoom = async (roomObject, hotelId) => {
	return await makeNetworkRequest(true, `/hotels/${hotelId}/rooms`, 'POST', roomObject);
}

export const getRoomById = async (hotelId, roomId) => {
	return await makeNetworkRequest(true, `/hotels/${hotelId}/rooms/${roomId}`, "GET");
};

export const updateHotelRoom = async (roomObject, hotelId) => {
	return await makeNetworkRequest(true, `/hotels/${hotelId}/rooms/${roomObject.id}`, 'PUT', roomObject);
}

export const getRoomTypes = async (hotelId) => {
	return await makeNetworkRequest(true, `/hotels/${hotelId}/room_types`, "GET");
};

export const storeRoomType = async (typeObject, hotelId) => {
	return await makeNetworkRequest(true, `/hotels/${hotelId}/room_types`, "POST", typeObject);
};

export const getGuestList = async (hotelId) => {
	return await makeNetworkRequest(true, `/hotels/${hotelId}/guests`, "GET");
};

export const getStats = async (hotelId, startDate, endDate) => {
	return await makeNetworkRequest(true, `/hotels/${hotelId}/stats?start_date=${startDate}&end_date=${endDate}`, "GET");
};

export const getAvailableRooms = async (hotelId, check_in, check_out, booking_id = null) => {
	let url = `/hotels/${hotelId}/available_rooms?check_in=${check_in}&check_out=${check_out}`;
	if (booking_id) {
		url = `${url}&booking_id=${booking_id}`;
	}
	return await makeNetworkRequest(true, url, "GET");
};

export const createBooking = async (bookingObject, hotelId) => {
	return await makeNetworkRequest(true, `/hotels/${hotelId}/bookings`, 'POST', bookingObject);
}

export const getBookings = async (hotelId, query = {}) => {
	return await makeNetworkRequest(true, `/hotels/${hotelId}/bookings`, "GET", {}, query);
};

export const getBookingById = async (hotelId, bookingId, { includeRooms, includeGuest }) => {
	let query = [];
	if (includeGuest) {
		query.push('includeGuest');
	}
	if (includeRooms) {
		query.push('includeRooms');
	}
	query = query.join('&');
	query = query ? `?${query}` : query;
	return await makeNetworkRequest(true, `/hotels/${hotelId}/bookings/${bookingId}${query}`, "GET");
};

export const updateBooking = async (bookingObject, hotelId) => {
	return await makeNetworkRequest(true, `/hotels/${hotelId}/bookings/${bookingObject.id}`, 'PUT', bookingObject);
}

export const getBookingPayments = async (hotelId, bookingId) => {
	return await makeNetworkRequest(true, `/hotels/${hotelId}/bookings/${bookingId}/payments`, "GET");
};

export const storeBookingPayment = async (hotelId, bookingId, data) => {
	return await makeNetworkRequest(true, `/hotels/${hotelId}/bookings/${bookingId}/payments`, "POST", data);
};

export const updateBookingPayment = async (hotelId, bookingId, paymentId, data) => {
	return await makeNetworkRequest(true, `/hotels/${hotelId}/bookings/${bookingId}/payments/${paymentId}`, "PUT", data);
};

export const generateInvoice = async (hotelId, bookingId, paymentId = null) => {
	return await makeFileRequest(true, `/hotels/${hotelId}/bookings/${bookingId}/invoice/${paymentId ?? ''}`, "GET", {}, {
		Accept: 'application/pdf',
	});
};

export const getUserRoles = async (hotelId) => {
	return await makeNetworkRequest(true, `/hotels/${hotelId}/roles`, 'GET');
};

export const getUserList = async (hotelId) => {
	return await makeNetworkRequest(true, `/hotels/${hotelId}/users`, 'GET');
};

export const getUserById = async (hotelId, userId) => {
	return await makeNetworkRequest(true, `/hotels/${hotelId}/users/${userId}`, 'GET');
};

export const createUser = async (userData, hotelId) => {
	return await makeNetworkRequest(true, `/hotels/${hotelId}/users`, 'POST', userData);
}

export const updateUser = async (userData, hotelId, userId) => {
	return await makeNetworkRequest(true, `/hotels/${hotelId}/users/${userId}`, 'PUT', userData);
}

export const uploadImageByResource = async (hotelId, resourceId, resourceType, image, isMainImage = false) => {
	const formData = new FormData();
	formData.append('image', image);
	formData.append('resource_id', resourceId);
	formData.append('resource_type', resourceType);
	formData.append('primary_image', isMainImage);
	return await makeNetworkRequest(true, `/hotels/${hotelId}/images`, 'POST', formData, {}, { 'Content-type': 'multipart/form-data' });
}

export const deleteImageByImageId = async (hotelId, imageId) => {
	return await makeNetworkRequest(true, `/hotels/${hotelId}/images/${imageId}`, 'DELETE');
}

// export const getAuthData = async (email, password) => {
//     //TODO:
//     return {
//         is_logged_in: true,
//         user_data: "admin"
//     };


//     const loginData = await makeRequest({
//         url: "/login",
//         method: "post",
//         data: {
//             email, password
//         }
//     });

//     if (loginData?.success) {
//         const authData = {
//             is_logged_in: true,
//             user_data: loginData.results.user,
//         };
//         const authToken = loginData.results.token;

//         window.localStorage.setItem(
//             STORAGE_KEY_AUTH_DATA,
//             JSON.stringify(authData)
//         );
//         window.localStorage.setItem(STORAGE_KEY_AUTH_TOKEN, authToken);
//         return authData;
//     } else {
//         // ToastPopup(
//         //     "error",
//         //     loginData?.message + (loginData?.errors ? ". " + Object.values(loginData?.errors) : "")
//         // );

//         console.error(loginData?.message + (loginData?.errors ? ". " + Object.values(loginData?.errors) : ""));
//     }

//     return { is_logged_in: false, user_data: {} };
// };



