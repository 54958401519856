/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ROUTE_PREFIX } from "../common/constants";
import useUserContext from "./useUserContext";
import ToastPopup from "../common/ToastPopup";

const useUserForm = (isEdit = false) => {
  const { isLoading, storeNewUser, loadRoles, roles, loadUserById, updateExistingUser } = useUserContext();
  const navigate = useNavigate();

  const [userId, setUserId] = useState(0);
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [password, setPassword] = useState('');
  const [role, setRole] = useState(null);

  useEffect(() => {
    loadRoles()
  }, []);

  const handleNameChange = (newName) => setName(newName);
  const handleEmailChange = (newEmail) => setEmail(newEmail);
  const handlePhoneChange = (newPhone) => setPhone(newPhone);
  const handlePasswordChange = (pass) => setPassword(pass);
  const handleRoleChange = (newRole) => setRole(newRole);

  const validateFormData = () => {
    if (!name.trim() || !email.trim() || !phone.trim() || !role) {
      return false;
    }
    if (userId) {
      if (password && password?.length < 8) {
        return false;
      }
    } else if (!password || password?.length < 8) {
      return false;
    }

    if (!roles?.find(r => r?.id === role?.value)) {
      return false;
    }
    return true;
  };

  const handleEditState = async (user) => {
    const {
      name,
      email,
      phone,
      roles: userRole,
    } = user;
    handleNameChange(name);
    handleEmailChange(email);
    handlePhoneChange(phone);
    handleRoleChange({ label: userRole[0].name.toUpperCase(), value: userRole[0].id });
  };

  const handleFormSubmit = async () => {
    if (!validateFormData()) {
      ToastPopup('error', 'Please input all data correctly');
      return;
    }

    const requestObject = {
      name,
      email,
      phone,
      password,
      role: role?.value,
    };

    if (!password) {
      delete requestObject.password;
    }
    let success = false;
    if (userId) {
      success = await updateExistingUser(requestObject, userId);
    } else {
      success = await storeNewUser(requestObject);
    }

    if (success) {
      navigate(ROUTE_PREFIX + '/users');
    }

  };

  return {
    loadUserById,
    handleNameChange,
    handleEmailChange,
    handlePhoneChange,
    handlePasswordChange,
    handleRoleChange,
    handleFormSubmit,
    handleEditState,
    setUserId,
    isLoading,
    roles,
    name,
    email,
    phone,
    password,
    role,
  };
}

export default useUserForm;