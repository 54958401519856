import DataTable from "react-data-table-component";
import useRoomList from "../../hooks/useRoomList";
import Calendar from "@demark-pro/react-booking-calendar";

const RoomAvailabilityCalendar = ({ data }) => {
  return (
    <div>
      <Calendar
        reserved={
          data?.availability?.map((bookedDates) => 
            ({ startDate: new Date(bookedDates?.check_in.replace(/\s.*$/, '') + ' 00:00:00'), endDate: new Date(bookedDates.check_out) }))
        }
      />
      <hr />
    </div>
  );
}

export default function BasicDataTable() {
  const { 
    columns,
    data,
    isLoading,
    totalResultCount,
    defaultPerPage,
    onPaginationPageChange,
    onPaginationPerRowsChange,
  } = useRoomList();

  return (
    <DataTable
      striped
      fixedHeader
      fixedHeaderScrollHeight="60vh"
      expandableRows={true}
      expandableRowsComponent={RoomAvailabilityCalendar}
      highlightOnHover
      columns={columns}
      progressPending={isLoading}
      data={data}
      pagination
      paginationServer
      paginationPerPage={defaultPerPage}
      paginationTotalRows={totalResultCount}
      paginationRowsPerPageOptions={[10, 15, 20, 25, 30, 50]}
      onChangePage={onPaginationPageChange}
      onChangeRowsPerPage={onPaginationPerRowsChange}
    />
  );
}
