/* eslint-disable react-hooks/exhaustive-deps */
import { createContext, useEffect, useState } from "react";
import useAuthContext from '../hooks/useAuthContext';
import { getRoomTypes, storeRoomType } from "../network/api";
import { STORAGE_KEY_ROOM_TYPES } from "../common/constants";

export const RoomTypeContext = createContext();

const getLocalRoomTypes = () => JSON.parse(window.localStorage.getItem(STORAGE_KEY_ROOM_TYPES) || '[]');

const RoomTypeContextProvider = (props) => {
  const [isLoading, setIsLoading] = useState(false);
  const { authData } = useAuthContext();
  const [roomTypes, setRoomTypes] = useState(getLocalRoomTypes());

  const loadRoomTypes = async (ignoreCache = false) => {
    setIsLoading(true);
    let res = [];
    const existing = JSON.parse(window.localStorage.getItem(STORAGE_KEY_ROOM_TYPES) || '[]');
    if (!ignoreCache && Array.isArray(existing) && existing.length) {
      setRoomTypes(existing);
      res = existing;
    }
    if (authData?.user_data?.hotel?.id) {
      const response = await getRoomTypes(authData?.user_data?.hotel?.id);
      window.localStorage.setItem(STORAGE_KEY_ROOM_TYPES, JSON.stringify(response?.results || []));
      setRoomTypes(response?.results || []);      
      res = response?.results || [];
    }
    setIsLoading(false);
    return res;
  }

  const createNewRoomType = async (type) => {
      setIsLoading(true);
      const response = await storeRoomType({ name: type },  authData?.user_data?.hotel?.id);
      if (response?.success) {
        await loadRoomTypes(true);
      }
      setIsLoading(false);
      return response?.results || null;
  }

  useEffect(() => {
    (async () => await loadRoomTypes())();
  }, []);

  useEffect(() => {
    (async () => await loadRoomTypes(true))();
  }, [authData]);

  return <RoomTypeContext.Provider value={{ roomTypes, isLoading, loadRoomTypes, createNewRoomType }}>{props.children}</RoomTypeContext.Provider>
}

export default RoomTypeContextProvider;