/* eslint-disable react-hooks/exhaustive-deps */
import { createContext, useEffect, useState } from "react";
import useAuthContext from '../hooks/useAuthContext';
import { getGuestList } from "../network/api";

export const GuestContext = createContext();

const GuestContextProvider = (props) => {
  const [isLoading, setIsLoading] = useState(false);
  const { authData } = useAuthContext();
  const [guests, setGuests] = useState([]);

  const loadGuests = async () => {
    if (authData?.user_data?.hotel?.id) {
      setIsLoading(true);
      const guestsList = await getGuestList(authData?.user_data?.hotel?.id);
      setGuests(guestsList?.results || []);
      setIsLoading(false);
    }
  }

  useEffect(() => {
    (async () => await loadGuests())(); 
  }, []);

  useEffect(() => {
    (async () => await loadGuests())(); 
  }, [authData]);

  return <GuestContext.Provider value={{ guests, isLoading, loadGuests }}>{props.children}</GuestContext.Provider>
}

export default GuestContextProvider;