import DropdownLink from "../components/Interface/DropdownLink";
import { ROUTE_PREFIX } from "../common/constants";
import useBookingContext from "./useBookingContext";
import CurrencyFormat from "react-currency-format";
import useIsAdmin from "./useIsAdmin";
import { useState } from "react";
const getBookingTableColumns = (isAdmin, actionCallback) => {
  const columns = [
    {
      name: "Booking No.",
      selector: (row) => `#${row.id}`,
      sortable: true,
    },
    {
      name: "Room No.",
      selector: (row) => row.rooms?.reduce((carry, room) => `${carry}, ${room.room_number}`, '').substring(1),
      sortable: false,
      wrap: true,
    },
    {
      name: "Check In",
      selector: (row) => new Date(row.check_in).toLocaleDateString().replace(/\//g, '.'),
      sortable: true,
    },
    {
      name: "Check Out",
      selector: (row) => new Date(row.check_out).toLocaleDateString().replace(/\//g, '.'),
      sortable: true,
    },
    {
      name: "Rent",
      selector: (row) => <CurrencyFormat prefix="৳" value={+row.total_price} suffix="/-" displayType="text" thousandSeparator="," />,
      sortable: false,
    },
    {
      name: "Paid",
      selector: (row) => <CurrencyFormat prefix="৳" value={+row.total_paid} suffix="/-" displayType="text" thousandSeparator="," />,
      sortable: true,
    },
    {
      name: "Booking Person",
      selector: (row) => row?.booking_person?.name,
      sortable: false,
      wrap: true,
    },
    {
      name: "Updated by",
      selector: (row) => row?.last_editor?.name || 'N/A',
      sortable: false,
      wrap: true,
    },
    {
      name: "Actions",
      selector: (row) => row?.action,
      sortable: true,
      cell: (row) => <DropdownLink
        firstLink={`${ROUTE_PREFIX}/room-bookings/edit-booking?booking=${row.id}`} firstLinkName="Edit"
        actionCallback={() => actionCallback(row?.id)} actionName="Payment"
      />,
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },    
  ];
  // if (isAdmin) {
  //   columns.push(
  //     {
  //       name: "Actions",
  //       selector: (row) => row?.action,
  //       sortable: true,
  //       cell: (row) => <DropdownLink
  //         firstLink={`${ROUTE_PREFIX}/room-bookings/edit-booking?booking=${row.id}`} firstLinkName="Edit"
  //         actionCallback={() => actionCallback(row?.id)} actionName="Payment"
  //       />,
  //       ignoreRowClick: true,
  //       allowOverflow: true,
  //       button: true,
  //     },
  //   )
  // }
  // if (!isAdmin) {
  //   columns.push(
  //     {
  //       name: "Actions",
  //       selector: (row) => row?.action,
  //       sortable: true,
  //       cell: (row) => <DropdownLink actionCallback={() => actionCallback(row?.id)} actionName="Payment"/>,
  //       ignoreRowClick: true,
  //       allowOverflow: true,
  //       button: true,
  //     },
  //   )
  // }
  return columns;
};

const defaultSearchParams = {
  check_in: null,
  check_out: null,
  room_number: '',
};

const useBookingList = (paymentModalTriggerCallback) => {

	const { bookings, isLoading, loadBookings, pageMeta } = useBookingContext();
  const [perPage, setPerPage] = useState(pageMeta?.per_page);
  const [searchParams, setSearchParams] = useState(defaultSearchParams);

  const isAdmin = useIsAdmin();

  const defaultPerPage = 30;

  const onPaginationPageChange = page => {
    loadBookings({...searchParams, page, per_page: perPage});
  }

  const onPaginationPerRowsChange = (rowPerPage, page) => {
    setPerPage(rowPerPage);
    loadBookings({...searchParams, page, per_page: rowPerPage});
  }

  const onSearchStart = () => {
    loadBookings({...searchParams, page: 1, per_page: perPage });
  }

  const onSearchCleared = () => {
    setSearchParams(defaultSearchParams);
    loadBookings({ page: 1 });
  }

  return {
		columns: getBookingTableColumns(isAdmin, paymentModalTriggerCallback),
		loadBookings,
    defaultPerPage,
    totalResultCount: pageMeta?.total || 0,
    onPaginationPageChange,
    onPaginationPerRowsChange,
		isLoading,
    searchParams,
    setSearchParams,
    onSearchStart,
    onSearchCleared,
		data: bookings,
  };
}

export default useBookingList;