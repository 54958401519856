/* eslint-disable jsx-a11y/anchor-is-valid */
import Select from "react-select";
import useImageForm, { MAXIMUM_IMAGE_PER_ROOM_TYPE, RESOURCE_TYPE_HOTEL, RESOURCE_TYPE_ROOM_TYPE } from "../../hooks/useImageForm";
 
export default function PhotoGallery() {
  const { 
          availableRoomTypes,
          isRoomTypeLoading,
          handleImageUpload,
          handleFileChange,
          selectedImage,
          isUploading,
          handleResourceChange,
          selectedResource,
          images,
          handleImageDelete,
          handleMainHotelImageSelection,
          isMainHotelImage,
          isHotelLoading,
          hotelInfo,
        } = useImageForm();
  const imageUrlPrefix = 'https://jo-reservation.sgp1.cdn.digitaloceanspaces.com/hotels/';

  return (
    <div className="shadow rounded bg-white">

      <div className="row justify-content-between align-items-center p-3">
        <div className="col-auto">
          <h5 className="mb-0 fw-bold">Photo Gallery</h5>
        </div>
      </div>

      <div className="col-8 offset-2">
        <div className="p-3">
          <form className="row gx-5 gy-3">
            <div className="col-md-6">
              <label className="form-label" htmlFor="roomType">Images For</label>
              <Select
                id="resourceTypeSelect"
                disabled={isRoomTypeLoading || isUploading || isHotelLoading}
                options={[
                  {
                    label: 'Hotel/Property',
                    value: 'Hotel',
                    resourceType: RESOURCE_TYPE_HOTEL,
                  },
                  ...availableRoomTypes.map(t => ({ label: t.name, value: t.id, resourceType: RESOURCE_TYPE_ROOM_TYPE }))
                ]}
                placeholder="Select a resource type"
                onChange={handleResourceChange}
                // value={selectedResource}
              />
              {(selectedResource && selectedResource?.resourceType === 'hotel') && (
                <div className="form-check">
                  <input style={{ border: '1px solid' }} onChange={ (e) => handleMainHotelImageSelection(e.target.checked) } className="form-check-input" type="checkbox" checked={isMainHotelImage} id="flexCheckChecked"/>
                  <label className="form-check-label" htmlFor="flexCheckChecked">
                    <strong>Primary image for hotel/property?</strong>
                  </label>
                </div>
              )}

            </div>

            {(Array.isArray([]) && images?.length > 0) && (
                  <>
                    <div className="col-12">
                      <h5>Uploaded Images</h5>
                    </div>
                    {
                      images?.map(image => {
                        return (<div key={image.id} className="col-4" style={{ position:'relative' }}>
                          <b>
                            <button 
                              disabled={isUploading || isRoomTypeLoading || isHotelLoading}
                              onClick={() => handleImageDelete(image.id)}
                              style={{ borderRadius: 50, backgroundColor: '#fff', right: 30, top: 5, position: 'absolute', color: 'red' }}
                              type="button"
                              className="btn-close"
                              aria-label="Close"></button>
                          </b>

                          <img src={`${imageUrlPrefix}${image.file_name}`} alt="Could not load"  className="img-thumbnail" />
                          {hotelInfo?.primary_image === image.file_name && (
                            <span><b>Primary Image</b></span>
                          )}
                        </div>)
                      })
                    }
                  </>
                )}

                {(selectedResource?.value && images.length < MAXIMUM_IMAGE_PER_ROOM_TYPE) && (
                  <>
                    <hr />
                    <div className="col-12">
                      <h5 style={{ marginBottom: 0 }}>Upload Image for {selectedResource.label}</h5>
                      <span style={{ color: 'green' }}>Maximum {MAXIMUM_IMAGE_PER_ROOM_TYPE} images per resource</span>
                    </div>

                  <div className="col-md-12">
                    <label htmlFor="roomRent" className="form-label">Select Image</label>
                    <input
                      type="file"
                      className="form-control"
                      id="imageInput"
                      accept="image/jpg, image/jpeg, image/png"
                      disabled={isUploading || isRoomTypeLoading || isHotelLoading}
                      onChange={({ target }) => {
                        handleFileChange(target.files[0]);
                      }}
                    />
                    <span style={{ color: 'red' }}>Image size should be between 50KB to 500KB</span>
                  </div>
                  <div className="col-md-12">
                    <button
                      type="button"
                      className="btn btn-secondary"
                      disabled={isUploading || isRoomTypeLoading || isHotelLoading ||  !selectedImage}
                      onClick={handleImageUpload}
                    >
                      {isUploading ? 'Uploading...' : 'Upload'}
                    </button>
                  </div>
                </>
                )}

          </form>
        </div>
      </div>
    </div>
  );
}
