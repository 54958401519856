import { login } from "../network/api";
import {
	AUTH_REDUCER_REMOVE_AUTH_DATA,
	AUTH_REDUCER_SET_AUTH_DATA,
	AUTH_REDUCER_SET_AUTH_DATA_SUCCESS,
	STORAGE_KEY_AUTH_DATA,
	STORAGE_KEY_ROOM_TYPES,
	TOKEN_STORAGE_KEY,
} from "../common/constants";
import ToastPopup from "../common/ToastPopup";

export const processLoginOperation = async (data) => {
	const { email, password } = data;

	const loginData = await login(email, password);
	if (loginData?.success) {
		window.localStorage.setItem(
			STORAGE_KEY_AUTH_DATA,
			JSON.stringify({
				is_logged_in: true,
				user_data: loginData.results.user,
			})
		);
		localStorage.setItem(TOKEN_STORAGE_KEY, loginData.results.token);
		if (loginData.results.user.email_verified_at == null) {
			window.location = `${window.location.origin}/verify-email`;
		}

		return JSON.parse(window.localStorage.getItem(STORAGE_KEY_AUTH_DATA));
	} else {
		ToastPopup(
			"error",
			loginData?.message + (loginData?.errors ? ". " + Object.values(loginData?.errors) : "")
		);
		processLogoutOperation();
	}

	return { is_logged_in: false, user_data: {} };
};

export const processLogoutOperation = async () => {
	window.localStorage.removeItem(STORAGE_KEY_AUTH_DATA);
	window.localStorage.removeItem(TOKEN_STORAGE_KEY);
	window.localStorage.removeItem(STORAGE_KEY_ROOM_TYPES);
};

export const AuthReducer = (state, action) => {
	switch (action.type) {
		case AUTH_REDUCER_SET_AUTH_DATA:
			processLoginOperation(action.payload).then((res) => {
				action.dispatch({ type: AUTH_REDUCER_SET_AUTH_DATA_SUCCESS, data: res });
			});
			return state;
		case AUTH_REDUCER_REMOVE_AUTH_DATA:
			processLogoutOperation();
			return { is_logged_in: false, user_data: {} };
		case AUTH_REDUCER_SET_AUTH_DATA_SUCCESS:
			return action.data;

		default:
			return state;
	}
};
